import {
  GET_STATS, GET_STATS_SUCCESS, GET_STATS_FAIL,
  GET_OPEN_AMOUNT_SUCCESS
} from "../../actions/stats";
import {LOGIN_WITH_JWT, LOGOUT_WITH_JWT} from "../../actions/auth/loginActions";


const initialState = {
  loading: false,
  managers: [],
  pagination: {},
  openAmount: null
};

const statsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATS:
      return {...state, loading: true};
    case GET_STATS_SUCCESS:
      return {...state, loading: false, managers: action.data, pagination: action.pagination};
    case GET_STATS_FAIL:
      return {...state, loading: false};
    case GET_OPEN_AMOUNT_SUCCESS:
      return {...state, openAmount: action.data};
    case LOGOUT_WITH_JWT:
    case LOGIN_WITH_JWT: {
      return initialState;
    }
    default:
      return state
  }
};

export default statsReducer
