import {
  GET_DASH_STATS, GET_DASH_STATS_SUCCESS, GET_DASH_STATS_FAIL,
  GET_DASH_CHART, GET_DASH_CHART_SUCCESS, GET_DASH_CHART_FAIL
} from "../../actions/dashboard";
import {LOGIN_WITH_JWT, LOGOUT_WITH_JWT} from "../../actions/auth/loginActions";


const initialState = {
  loading: false,
  stats: {},
  chart: {},
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASH_STATS:
    case GET_DASH_CHART:
      return {...state, loading: true};
    case GET_DASH_STATS_SUCCESS:
      return {...state, loading: false, stats: action.data};
    case GET_DASH_CHART_SUCCESS:
      return {...state, loading: false, chart: action.data};
    case GET_DASH_STATS_FAIL:
    case GET_DASH_CHART_FAIL:
      return {...state, loading: false};
    case LOGOUT_WITH_JWT:
    case LOGIN_WITH_JWT: {
      return initialState;
    }
    default:
      return state
  }
};

export default dashboardReducer
