import {createStore, applyMiddleware, compose} from "redux"
import createDebounce from "redux-debounced"
import thunk from "redux-thunk"
import rootReducer from "../reducers/rootReducer"
import logger from 'redux-logger'
import middlewareAuth from '../middleware/auth'

let middlewares = [];
if (process.env.NODE_ENV === 'development' /*|| !process.env.REACT_APP_DEPLOY*/) {
  middlewares = [thunk, createDebounce(), logger, middlewareAuth()]
} else {
  middlewares = [thunk, createDebounce(), middlewareAuth()];
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  {},
  composeEnhancers(applyMiddleware(...middlewares))
)

export {store}
