import axios from "axios";
import humps from 'humps';
import {API_URL} from "../../../configs/constants";
import {getErrorMessage, isError} from "../../../utility/helpers";
import {setFetchMessage} from "../notification";
import notices from "../../../utility/notices";

export const GET_PUBLIC_IP = "GET_PUBLIC_IP";
export const GET_PUBLIC_IP_SUCCESS = "GET_PUBLIC_IP_SUCCESS";
export const GET_PUBLIC_IP_FAIL = "GET_PUBLIC_IP_FAIL";
export const SET_MODEM = "SET_MODEM";

/*export const getPublicIP = (profileId) => {
  return async dispatch => {
    dispatch({type: GET_PUBLIC_IP, profileId});
    try {
      const response = await axios.post(`${API_URL}/api/settings/proxy-modem/receive-ip`, humps.decamelizeKeys({profileId}));
      isError(response);
      dispatch({
        type: GET_PUBLIC_IP_SUCCESS,
        data: humps.camelizeKeys({...response.data, idWhichActivatedModem: profileId}),
        profileId
      })
    } catch (error) {
      dispatch({type: GET_PUBLIC_IP_FAIL, profileId});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};*/


export const setModem = (proxy = {}, profileId, isNeedUpdateProxyModemIp, allowWorkWithoutProxy) => (dispatch, getState) => {
  let proxyModem = getState().auth.login.user.proxyModem || {},
    idWhichActivatedModem = profileId,
    modem = {...proxy, index: proxyModem.index, allowWorkWithoutProxy};
  if (!isNeedUpdateProxyModemIp) {
    modem = {...modem, idWhichActivatedModem}
  }
  dispatch({type: SET_MODEM, modem});
};

export const getPublicIP = (profileId) => {
  return async (dispatch, getState) => {
    const data = humps.decamelizeKeys({profileId});
    const modemState = getState().modem;
    const {loading, modem: {publicIp, index}} = modemState;
    if (!index) {
      dispatch(setFetchMessage(notices.notSelectedModemUser, 'error'));
    } else {
      if (loading) {
        dispatch(setFetchMessage(notices.isLockModem, 'warning'));
      } else {
        dispatch({type: GET_PUBLIC_IP, profileId});
        try {
          await axios.post(`${API_URL}/api/settings/proxy-modem/reboot-modem`, data);
          const response = await getModem(data, 40000, 1, publicIp);
          isError(response);
          dispatch({
            type: GET_PUBLIC_IP_SUCCESS,
            data: humps.camelizeKeys({...response.data, idWhichActivatedModem: profileId}),
            profileId
          });
          return response;
        } catch (error) {
          dispatch({type: GET_PUBLIC_IP_FAIL, profileId});
          dispatch(setFetchMessage(getErrorMessage(error), 'error'));
        }
      }
    }
  }
};

const getModem = async (data, ms, attempt, oldIp) => {
  const timeout = (ms) => new Promise(resolve => setTimeout(resolve, ms));
  await timeout(ms);
  const response = await axios.post(`${API_URL}/api/settings/proxy-modem/change-ip`, data);

  if ((response.status === 205 || (response.data && response.data['modem_public_ip'] === oldIp)) && attempt < 5) {
    return await getModem(data, 20000, attempt + 1, oldIp)
  }
  let result = humps.camelizeKeys(response);
  let {modemPublicIp, modemIndex, proxyLogin, proxyPassword, proxyHttp} = result.data;
  return {
    ...result, data: {
      index: modemIndex,
      publicIp: modemPublicIp,
      login: proxyLogin,
      password: proxyPassword,
      proxyHttp
    }
  }
};
