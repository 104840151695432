import axios from "axios";
import humps from 'humps';
import {API_URL} from "../../../configs/constants";
import {setFetchMessage} from "../notification";
import {getErrorMessage, isError} from "../../../utility/helpers";
import {setModem} from "../modem";
import notices from "../../../utility/notices";

export const GET_PROFILES = "GET_PROFILES";
export const GET_PROFILES_SUCCESS = "GET_PROFILES_SUCCESS";
export const GET_PROFILES_FAIL = "GET_PROFILES_FAIL";
export const SAVE_AMZ_ACCOUNT = "SAVE_AMZ_ACCOUNT";
export const SAVE_AMZ_ACCOUNT_SUCCESS = "SAVE_AMZ_ACCOUNT_SUCCESS";
export const SAVE_AMZ_ACCOUNT_FAIL = "SAVE_AMZ_ACCOUNT_FAIL";
export const LOCK_PROFILE = "LOCK_PROFILE";
export const LOCK_PROFILE_SUCCESS = "LOCK_PROFILE_SUCCESS";
export const LOCK_PROFILE_FAIL = "LOCK_PROFILE_FAIL";
export const UNLOCK_PROFILE = "UNLOCK_PROFILE";
export const UNLOCK_PROFILE_SUCCESS = "UNLOCK_PROFILE_SUCCESS";
export const UNLOCK_PROFILE_FAIL = "UNLOCK_PROFILE_FAIL";
export const UPDATE_PROFILE_EMAIL = "UPDATE_PROFILE_EMAIL";
export const UPDATE_PROFILE_EMAIL_SUCCESS = "UPDATE_PROFILE_EMAIL_SUCCESS";
export const UPDATE_PROFILE_EMAIL_FAIL = "UPDATE_PROFILE_EMAIL_FAIL";
export const CREATE_MANUAL_PROFILE = "CREATE_MANUAL_PROFILE";
export const CREATE_MANUAL_PROFILE_SUCCESS = "CREATE_MANUAL_PROFILE_SUCCESS";
export const CREATE_MANUAL_PROFILE_FAIL = "CREATE_MANUAL_PROFILE_FAIL";
export const GENERATE_PROFILES = "GENERATE_PROFILES";
export const GENERATE_PROFILES_SUCCESS = "GENERATE_PROFILES_SUCCESS";
export const GENERATE_PROFILES_FAIL = "GENERATE_PROFILES_FAIL";
export const REMOVE_PROFILE = "REMOVE_PROFILE";
export const REMOVE_PROFILE_SUCCESS = "REMOVE_PROFILE_SUCCESS";
export const REMOVE_PROFILE_FAIL = "REMOVE_PROFILE_FAIL";

export const typesProfile = {
  1: {path: "blank", name: 'accounts'},
  2: {path: "cold", name: 'freezer'},
};

export const getProfiles = (profilesTypeId) => {
  const payload = typesProfile[profilesTypeId];
  return async (dispatch, getState) => {
    const state = getState();
    payload.userId = state.auth.login.user.id;
    dispatch({type: GET_PROFILES, payload});
    try {
      const response = await axios.get(`${API_URL}/api/profile/${payload.path}/list`);
      isError(response);
      dispatch({
        type: GET_PROFILES_SUCCESS,
        data: response.data.profiles,
        payload
      })
    } catch (error) {
      dispatch({type: GET_PROFILES_FAIL, payload})
    }
  }
};

export const saveAmazonAccountReady = (data) => saveAmazonAccount(data, "/api/profile/create-ready-from-blank");

export const saveAmazonAccountFreezer = (data) => saveAmazonAccount(data, "/api/profile/create-cold-from-blank");

export const saveAmazonAccount = (data, path) => {
  return async (dispatch) => {
    dispatch({type: SAVE_AMZ_ACCOUNT});
    return await axios
      .post(`${API_URL}${path}`, {...humps.decamelizeKeys(data), "profile_id": data.id})
      .then(response => {
        isError(response);
        dispatch({
          type: SAVE_AMZ_ACCOUNT_SUCCESS,
          id: data.id,
        });
        return response;
      }).catch(error => {
        dispatch({type: SAVE_AMZ_ACCOUNT_FAIL});
        dispatch(setFetchMessage(getErrorMessage(error), 'error'));
      })
  }
};

export const lockProfile = (profileId, profilesTypeId) => {
  let payload = {...typesProfile[profilesTypeId], profileId};
  return async dispatch => {
    dispatch({type: LOCK_PROFILE, payload});
    try {
      const response = await axios.get(`${API_URL}/api/profile/${payload.path}/profile/${profileId}`);
      const result = humps.camelizeKeys(response.data);
      const {profile = {}, isNeedUpdateProxyModemIp, proxy = {}, allowWorkWithoutProxy} = result;
      dispatch({
        type: LOCK_PROFILE_SUCCESS,
        data: profile,
        payload: {...payload, locked: response.status === 205}
      });
      profilesTypeId !== 2 && dispatch(setModem(proxy, profile.id, isNeedUpdateProxyModemIp, allowWorkWithoutProxy));
    } catch (error) {
      dispatch({type: LOCK_PROFILE_FAIL, payload});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const unlockProfile = (profileId, profilesTypeId) => {
  let payload = {...typesProfile[profilesTypeId], profileId};
  return async dispatch => {
    dispatch({type: UNLOCK_PROFILE});
    try {
      await axios.post(`${API_URL}/api/profile/unlock`, {"profile_id": profileId});
      dispatch({
        type: UNLOCK_PROFILE_SUCCESS,
        payload: {...payload, locked: false}
      })
    } catch (error) {
      dispatch({type: UNLOCK_PROFILE_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const updateProfileEmail = (data, name) => {
  return async dispatch => {
    dispatch({type: UPDATE_PROFILE_EMAIL});
    try {
      await axios.post(`${API_URL}/api/profile/update/email`, humps.decamelizeKeys(data));
      dispatch({
        type: UPDATE_PROFILE_EMAIL_SUCCESS,
      });
      dispatch(setFetchMessage(notices.updateField(name), 'success'));
    } catch (error) {
      dispatch({type: UPDATE_PROFILE_EMAIL_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const createManualProfile = (data) => {
  return async dispatch => {
    dispatch({type: CREATE_MANUAL_PROFILE});
    try {
      const response = await axios.post(`${API_URL}/api/profile/create-manual`, data);
      dispatch({
        type: CREATE_MANUAL_PROFILE_SUCCESS,
        data: response.data.profile
      });
      dispatch(setFetchMessage(notices.createdItem(`New profile #${response.data.profile.id}`), 'success'));
      return response;
    } catch (error) {
      dispatch({type: CREATE_MANUAL_PROFILE_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const generateProfiles = (number) => {
  return async dispatch => {
    dispatch({type: GENERATE_PROFILES});
    try {
      const response = await axios.post(`${API_URL}/api/profile/generate/blank`, humps.decamelizeKeys({number}));
      dispatch({
        type: GENERATE_PROFILES_SUCCESS,
        data: response.data.profiles
      });
      dispatch(setFetchMessage(notices.createdItem(`${number} Profile${number > 1 ? 's' : ''}`), 'success'));
      return response;
    } catch (error) {
      dispatch({type: GENERATE_PROFILES_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const removeProfile = (profileId) => {
  return async dispatch => {
    dispatch({type: REMOVE_PROFILE});
    try {
      const response = await axios.post(`${API_URL}/api/profile/remove/bad`, humps.decamelizeKeys({profileId}));
      dispatch({
        type: REMOVE_PROFILE_SUCCESS,
        id: profileId
      });
      dispatch(setFetchMessage(notices.deleteItem(`Profile #${profileId}`), 'success'));
      return response;
    } catch (error) {
      dispatch({type: REMOVE_PROFILE_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
