import axios from "axios";
import humps from 'humps';
import {API_URL} from "../../../configs/constants";
import {setFetchMessage} from "../notification";
import {getErrorMessage, isError, normalizedSalesProfile} from "../../../utility/helpers";
import notices from "../../../utility/notices";
import {setModem} from "../modem";

export const GET_ORDERS = 'GET_ORDERS';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAIL = 'GET_ORDERS_FAIL';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAIL = 'UPDATE_PROFILE_FAIL';
export const ATTACH_MANAGE = 'ATTACH_MANAGE';
export const ATTACH_MANAGE_SUCCESS = 'ATTACH_MANAGE_SUCCESS';
export const ATTACH_MANAGE_FAIL = 'ATTACH_MANAGE_FAIL';
export const ATTACH_LOCKER = 'ATTACH_LOCKER';
export const ATTACH_LOCKER_SUCCESS = 'ATTACH_LOCKER_SUCCESS';
export const ATTACH_LOCKER_FAIL = 'ATTACH_LOCKER_FAIL';
export const ATTACH_WISHLIST = 'ATTACH_WISHLIST';
export const ATTACH_WISHLIST_SUCCESS = 'ATTACH_WISHLIST_SUCCESS';
export const ATTACH_WISHLIST_FAIL = 'ATTACH_WISHLIST_FAIL';
export const ORDER_BOUGHT = 'ORDER_BOUGHT';
export const ORDER_BOUGHT_SUCCESS = 'ORDER_BOUGHT_SUCCESS';
export const ORDER_BOUGHT_FAIL = 'ORDER_BOUGHT_FAIL';
export const UPDATE_FAKE_ADDRESS = 'UPDATE_FAKE_ADDRESS';
export const UPDATE_FAKE_ADDRESS_SUCCESS = 'UPDATE_FAKE_ADDRESS_SUCCESS';
export const UPDATE_FAKE_ADDRESS_FAIL = 'UPDATE_FAKE_ADDRESS_FAIL';
export const GET_RANDOM_PRODUCTS = 'GET_RANDOM_PRODUCTS';
export const GET_RANDOM_PRODUCTS_SUCCESS = 'GET_RANDOM_PRODUCTS_SUCCESS';
export const GET_RANDOM_PRODUCTS_FAIL = 'GET_RANDOM_PRODUCTS_FAIL';
export const GET_CAMPAIGNS = 'GET_CAMPAIGNS';
export const GET_CAMPAIGNS_SUCCESS = 'GET_CAMPAIGNS_SUCCESS';
export const GET_CAMPAIGNS_FAIL = 'GET_CAMPAIGNS_FAIL';
export const GET_CAMPAIGNS_AVAILABLE = 'GET_CAMPAIGNS_AVAILABLE';
export const GET_CAMPAIGNS_AVAILABLE_SUCCESS = 'GET_CAMPAIGNS_AVAILABLE_SUCCESS';
export const GET_CAMPAIGNS_AVAILABLE_FAIL = 'GET_CAMPAIGNS_AVAILABLE_FAIL';
export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_FAIL = 'GET_ACCOUNTS_FAIL';
export const GET_ORDERS_GIFT = 'GET_ORDERS_GIFT';
export const GET_ORDERS_GIFT_SUCCESS = 'GET_ORDERS_GIFT_SUCCESS';
export const GET_ORDERS_GIFT_FAIL = 'GET_ORDERS_GIFT_FAIL';
export const GET_ORDERS_READY = 'GET_ORDERS_READY';
export const GET_ORDERS_READY_SUCCESS = 'GET_ORDERS_READY_SUCCESS';
export const GET_ORDERS_READY_FAIL = 'GET_ORDERS_READY_FAIL';
export const ORDER_NEW = 'ORDER_NEW';
export const ORDER_NEW_SUCCESS = 'ORDER_NEW_SUCCESS';
export const ORDER_NEW_FAIL = 'ORDER_NEW_FAIL';
export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_FAIL = 'GET_ORDER_FAIL';
export const ORDER_LOCK = 'ORDER_LOCK';
export const ORDER_LOCK_SUCCESS = 'ORDER_LOCK_SUCCESS';
export const ORDER_LOCK_FAIL = 'ORDER_LOCK_FAIL';
export const ORDER_LOCK_PRODUCTS = 'ORDER_LOCK_PRODUCTS';
export const ORDER_LOCK_PRODUCTS_SUCCESS = 'ORDER_LOCK_PRODUCTS_SUCCESS';
export const ORDER_LOCK_PRODUCTS_FAIL = 'ORDER_LOCK_PRODUCTS_FAIL';
export const ORDER_LOCK_GIFT = 'ORDER_LOCK_GIFT';
export const ORDER_LOCK_GIFT_SUCCESS = 'ORDER_LOCK_GIFT_SUCCESS';
export const ORDER_LOCK_GIFT_FAIL = 'ORDER_LOCK_GIFT_FAIL';
export const LOCK_REVIEW_JOB = 'LOCK_REVIEW_JOB';
export const LOCK_REVIEW_JOB_SUCCESS = 'LOCK_REVIEW_JOB_SUCCESS';
export const LOCK_REVIEW_JOB_FAIL = 'LOCK_REVIEW_JOB_FAIL';
export const UNLOCK_REVIEW_JOB = 'UNLOCK_REVIEW_JOB';
export const UNLOCK_REVIEW_JOB_SUCCESS = 'UNLOCK_REVIEW_JOB_SUCCESS';
export const UNLOCK_REVIEW_JOB_FAIL = 'UNLOCK_REVIEW_JOB_FAIL';
export const REMOVE_REVIEW_JOB = 'REMOVE_REVIEW_JOB';
export const REMOVE_REVIEW_JOB_SUCCESS = 'REMOVE_REVIEW_JOB_SUCCESS';
export const REMOVE_REVIEW_JOB_FAIL = 'REMOVE_REVIEW_JOB_FAIL';
export const GET_PRODUCT = 'GET_PRODUCT';
export const GET_PRODUCT_SUCCESS = 'GET_PRODUCT_SUCCESS';
export const GET_PRODUCT_FAIL = 'GET_PRODUCT_FAIL';
export const GET_RANDOM_PRODUCT = 'GET_RANDOM_PRODUCT';
export const GET_RANDOM_PRODUCT_SUCCESS = 'GET_RANDOM_PRODUCT_SUCCESS';
export const GET_RANDOM_PRODUCT_FAIL = 'GET_RANDOM_PRODUCT_FAIL';
export const ORDER_FINISH = 'ORDER_FINISH';
export const ORDER_FINISH_SUCCESS = 'ORDER_FINISH_SUCCESS';
export const ORDER_FINISH_FAIL = 'ORDER_FINISH_FAIL';
export const ATTACH_SCREENSHOT = 'ATTACH_SCREENSHOT';
export const ATTACH_SCREENSHOT_SUCCESS = 'ATTACH_SCREENSHOT_SUCCESS';
export const ATTACH_SCREENSHOT_FAIL = 'ATTACH_SCREENSHOT_FAIL';
export const GET_PRODUCT_IMAGE = 'GET_PRODUCT_IMAGE';
export const GET_PRODUCT_IMAGE_SUCCESS = 'GET_PRODUCT_IMAGE_SUCCESS';
export const GET_PRODUCT_IMAGE_FAIL = 'GET_PRODUCT_IMAGE_FAIL';

export const UPDATE_ACTIVE_PROFILE = 'UPDATE_ACTIVE_PROFILE';
export const SET_WORKFLOW_TYPE_ID = 'SET_WORKFLOW_TYPE_ID';
export const SET_ACTIVE_ORDER = 'SET_ACTIVE_ORDER';
export const DELETE_REVIEW_JOB = 'DELETE_REVIEW_JOB';
export const ADD_REVIEW_JOB = 'ADD_REVIEW_JOB';
export const SET_REVIEW_JOBS_ERROR = 'SET_REVIEW_JOBS_ERROR';
export const RESET_FETCH_ERROR = 'RESET_FETCH_ERROR';
export const RESET_FETCH_SUCCESS = 'RESET_FETCH_SUCCESS';

export const getProfile = (data, path, isSales) => {
  return async dispatch => {
    dispatch({type: GET_PROFILE});
    try {
      const response = await axios[isSales ? 'get' : 'post'](`${API_URL}${path}`, humps.decamelizeKeys(data));
      isError(response);
      const result = humps.camelizeKeys(response.data);
      const {profile, salesProfile, isNeedUpdateProxyModemIp, proxy = {}, allowWorkWithoutProxy} = result;

      dispatch({
        type: GET_PROFILE_SUCCESS,
        data: salesProfile ? normalizedSalesProfile(salesProfile) : profile,
      });

      profile && dispatch(setModem(proxy, profile.id, isNeedUpdateProxyModemIp, allowWorkWithoutProxy));

      return salesProfile ? normalizedSalesProfile(salesProfile) : profile;
    } catch (error) {
      dispatch({type: GET_PROFILE_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const getProfileRandom = (data, isSales) => getProfile(data, isSales ? '/api/sales/profile/create' : '/api/order/choose/workflow');
export const getProfileById = (data, isSales) => getProfile(data, isSales ? `/api/sales/profile/${data['profile_id']}` : '/api/order/choose/profile', isSales);

export const updateSalesProfile = (data, id) => {
  return async dispatch => {
    dispatch({type: UPDATE_PROFILE});
    try {

      const response = await axios.patch(`${API_URL}/api/sales/profile/update/${id}`, humps.decamelizeKeys(data));
      isError(response);

      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        data,
      });

      return response
    } catch (error) {
      dispatch({type: UPDATE_PROFILE_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const getAccounts = (type, activeProfileId, isSales) => {
  return async dispatch => {
    dispatch({type: GET_ACCOUNTS});
    try {
      // /api/sales/profile/workflow/list
      const response = await axios[isSales ? 'get' : 'post'](`${API_URL}/api/${isSales ? 'sales/profile' : 'order'}/workflow/list`, {"type_workflow": type});
      isError(response);
      const result = humps.camelizeKeys(response.data);
      dispatch({
        type: GET_ACCOUNTS_SUCCESS,
        data: result[isSales ? 'salesProfiles' : 'profiles'],
        payload: activeProfileId
      })
    } catch (error) {
      dispatch({type: GET_ACCOUNTS_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const getRandomProducts = (data, payload) => {
  return async dispatch => {
    dispatch({type: GET_RANDOM_PRODUCTS});
    try {
      // /api/sales/profile/random-campaigns
      const response = await axios.post(`${API_URL}/api/${payload.isSales ? 'sales/profile' : 'order'}/random-campaigns?`, humps.decamelizeKeys(data));
      isError(response);
      if (response.data.campaigns.length === 0) {
        dispatch(setFetchMessage(notices.noMatchingCampaigns, 'warning'));
      }
      dispatch({
        type: GET_RANDOM_PRODUCTS_SUCCESS,
        data: response.data.campaigns,
        payload
      })
    } catch (error) {
      dispatch({type: GET_RANDOM_PRODUCTS_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const getProductImage = (productId, payload) => {
  payload.id = productId;
  return async dispatch => {
    dispatch({type: GET_PRODUCT_IMAGE, payload});
    try {
      const response = await axios.get(`${API_URL}/api/order/product/${productId}/image`);
      isError(response);

      dispatch({
        type: GET_PRODUCT_IMAGE_SUCCESS,
        data: {campaign: {...payload.campaign, product: response.data.product}, updateImage: true},
        payload
      })
    } catch (error) {
      dispatch({type: GET_PRODUCT_IMAGE_FAIL, payload});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const getCampaigns = (data, isSales) => {
  return async dispatch => {
    dispatch({type: GET_CAMPAIGNS});
    try {
      const response = await axios.post(`${API_URL}/api/${isSales ? 'sales/profile' : 'order'}/campaigns`, humps.decamelizeKeys(data));
      isError(response);
      dispatch({
        type: GET_CAMPAIGNS_SUCCESS,
        data: response.data.campaigns,
      })
    } catch (error) {
      dispatch({type: GET_CAMPAIGNS_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const getCampaignsAvailable = (isSales) => {
  return async dispatch => {
    dispatch({type: GET_CAMPAIGNS_AVAILABLE});
    try {
      const response = await axios.get(`${API_URL}/api/${isSales ? 'sales/profile' : 'order'}/campaign/availability`);

      isError(response);
      dispatch({
        type: GET_CAMPAIGNS_AVAILABLE_SUCCESS,
        data: isSales ? {sales: humps.camelizeKeys(response.data)} : humps.camelizeKeys(response.data),
      })
    } catch (error) {
      dispatch({type: GET_CAMPAIGNS_AVAILABLE_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

const getItemById = async (campaignId, {withCodeword, delivery, amzSellers = {}, isSales}, dispatch) => {
  const response = await axios.get(`${API_URL}/api/${isSales ? 'sales/profile' : 'order'}/campaign/${campaignId}`);
  isError(response);
  let isValid = true;
  if (response.data.campaign) {
    const {codewordCampaign, product: {amzSellerName}} = humps.camelizeKeys(response.data.campaign);
    if (withCodeword && !codewordCampaign) {
      dispatch(setFetchMessage(notices.notCodeword(campaignId), 'warning'));
      isValid = false;
    }
    if (!isSales && isValid && (response.data.campaign.product.delivery !== delivery ||
      (!withCodeword && codewordCampaign))) {
      dispatch(setFetchMessage(notices.warningTypeProduct, 'warning'));
      isValid = false;
    }
    if (amzSellers[amzSellerName] >= 2) {
      dispatch(setFetchMessage(notices.productsFromOneSeller, 'warning'));
      isValid = false;
    }
  } else {
    dispatch(setFetchMessage(notices.campaignNotFound(campaignId), 'warning'));
    isValid = false;
  }
  return isValid ? [response.data.campaign] : []
};
export const getCampaignById = (campaignId, payload) => {
  return async dispatch => {
    dispatch({type: GET_CAMPAIGNS});
    try {
      const data = await getItemById(campaignId, payload, dispatch);
      dispatch({
        type: GET_CAMPAIGNS_SUCCESS,
        data: {data},
      })
    } catch (error) {
      dispatch({type: GET_CAMPAIGNS_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const getProduct = (campaignId, payload) => {
  return async dispatch => {
    dispatch({type: GET_PRODUCT});
    try {
      const data = await getItemById(campaignId, payload, dispatch);

      dispatch({
        type: GET_PRODUCT_SUCCESS,
        data,
        payload
      });
    } catch (error) {
      dispatch({type: GET_PRODUCT_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const attachManage = (data, payload, path) => {
  return async dispatch => {
    dispatch({type: ATTACH_MANAGE});
    try {
      const response = await axios.post(`${API_URL}/api/order/attach/${path}`, data);
      isError(response);
      dispatch({
        type: ATTACH_MANAGE_SUCCESS,
        data: response.data,
        payload
      });
      return response
    } catch (error) {
      dispatch({
        type: ATTACH_MANAGE_FAIL,
        error: error.response ? error.response : error
      })
    }
  }
};
export const attachLocker = (data, payload) => attachManage(data, payload, 'amazon-hub-locker');
export const attachWishlist = (data, payload) => attachManage(data, payload, 'wishlist');
export const attachTesterAddress = (data, payload) => attachManage(data, payload, 'tester-address');

export const fetchOrderBought = (data, isSales) => {
  return async dispatch => {
    dispatch({type: ORDER_BOUGHT});
    try {
      const response = await axios.post(`${API_URL}/api/${isSales ? 'sales/profile' : 'order'}/bought`, humps.decamelizeKeys(data));
      isError(response);
      dispatch({
        type: ORDER_BOUGHT_SUCCESS,
        payload: {key: 'status', data: 'bought', allChange: true}
      });
      dispatch(setFetchMessage(notices.orderBought, 'success'));
      return response
    } catch (error) {
      dispatch({type: ORDER_BOUGHT_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const updateFakeAddress = (profileId, billingStreet) => {
  return async dispatch => {
    dispatch({type: UPDATE_FAKE_ADDRESS});
    try {
      const response = await axios.post(`${API_URL}/api/order/update/fake-address`, humps.decamelizeKeys({
        profileId,
        billingStreet
      }));
      isError(response);
      dispatch({
        type: UPDATE_FAKE_ADDRESS_SUCCESS,
        data: response.data,
        payload: billingStreet
      });
      dispatch(setFetchMessage(notices.updateField('street'), 'success'));
      return response
    } catch (error) {
      dispatch({
        type: UPDATE_FAKE_ADDRESS_FAIL,
        error: error.response ? error.response : error
      })
    }
  }
};

export const lockReviewJob = (data, oldReviewJob, isSales) => {
  let {campaignId, campaign} = oldReviewJob;
  return async dispatch => {
    dispatch({type: LOCK_REVIEW_JOB, campaignId});
    try {
      const response = await axios.post(`${API_URL}/api/${isSales ? 'sales/profile' : 'order'}/lock-product`, humps.decamelizeKeys(data));
      isError(response);

      const {reviewJob, salesOrder} = humps.camelizeKeys(response.data);
      if (!isSales) {
        if (reviewJob.option === 'Only_text') {
          campaign = {...campaign, countTextReviewJobs: +campaign.countTextReviewJobs + 1}
        }
        if (reviewJob.option === 'Only_star') {
          campaign = {...campaign, countStarReviewJobs: +campaign.countStarReviewJobs + 1}
        }
      }

      dispatch({
        type: LOCK_REVIEW_JOB_SUCCESS,
        data: {...isSales ? salesOrder : reviewJob, campaign},
        campaignId,
        orderId: isSales ? data.salesProfileId : data.orderId

      });
    } catch (error) {
      dispatch({type: LOCK_REVIEW_JOB_FAIL, campaignId});
      let message = getErrorMessage(error);
      if (message === 'Wrong product price') {
        dispatch(setFetchMessage(`The price of the product ${oldReviewJob.campaign.product.asin} on Amazon does not match the price of the campaign. Product removed.`, 'error'));
        dispatch(removeReviewJob({
          ...isSales ? {salesProfileId: data.orderId, salesOrderId: oldReviewJob.id} : {orderId: data.orderId},
          productId: oldReviewJob.campaign.productId,
          exclusiveStatus: 'price_wrong',
          reviewJobId: oldReviewJob.id
        }, {...data, isSales}));
      } else {
        dispatch(setFetchMessage(message, 'error'));
      }
    }
  }
};

export const unlockReviewJob = (data, reviewJob, isSales) => {
  let {id, campaignId, option, campaign, ...rest} = reviewJob;
  if (option === 'Only_text') {
    campaign = {...campaign, countTextReviewJobs: +campaign.countTextReviewJobs - 1}
  }
  if (option === 'Only_star') {
    campaign = {...campaign, countStarReviewJobs: +campaign.countStarReviewJobs - 1}
  }
  return async dispatch => {
    dispatch({type: UNLOCK_REVIEW_JOB, campaignId});
    try {
      const response = await axios.post(`${API_URL}/api/${isSales ? 'sales/profile' : 'order'}/remove-locked-product`, humps.decamelizeKeys(data));
      isError(response);
      dispatch({
        type: UNLOCK_REVIEW_JOB_SUCCESS,
        data: {campaignId, ...rest, option: null, campaign},
        campaignId,
        orderId: isSales ? data.salesProfileId : data.orderId
      })
    } catch (error) {
      dispatch({type: UNLOCK_REVIEW_JOB_FAIL, campaignId})
    }
  }
};

export const removeReviewJob = (data, payload) => {
  return async dispatch => {
    dispatch({type: REMOVE_REVIEW_JOB, ...payload});
    try {
      const response = await axios.post(`${API_URL}/api/${payload.isSales ? 'sales/profile' : 'order'}/remove-locked-product`, humps.decamelizeKeys(data));
      isError(response);
      dispatch({
        type: REMOVE_REVIEW_JOB_SUCCESS,
        ...payload
      })
    } catch (error) {
      dispatch({type: REMOVE_REVIEW_JOB_FAIL, ...payload})
    }
  }
};

export const orderFinish = (data, isSales) => {

  return async dispatch => {
    dispatch({type: ORDER_FINISH});
    try {
      const response = await axios.post(`${API_URL}/api/${isSales ? 'sales/profile' : 'order'}/finish`, humps.decamelizeKeys(data));
      isError(response);
      dispatch({type: ORDER_FINISH_SUCCESS});
      dispatch(setFetchMessage(notices.orderFinish, 'success'));
      return response;
    } catch (error) {
      dispatch({type: ORDER_FINISH_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const attachScreenshot = (data) => {

  return async dispatch => {
    dispatch({type: ATTACH_SCREENSHOT});
    try {
      const response = await axios.post(`${API_URL}/api/review-job/add/screenshot`, data);
      isError(response);
      dispatch({type: ATTACH_SCREENSHOT_SUCCESS});
      return humps.camelizeKeys(response.data).reviewJob;
    } catch (error) {
      dispatch({type: ATTACH_SCREENSHOT_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const setWorkflowTypeId = (workflowTypeId) => {
  return dispatch => {
    dispatch({
      type: SET_WORKFLOW_TYPE_ID,
      workflowTypeId,
    })
  }
};
export const updateActiveProfile = (data) => {
  return dispatch => {
    dispatch({
      type: UPDATE_ACTIVE_PROFILE,
      data
    })
  }
};
export const setReviewJobsError = (data) => {
  return dispatch => {
    dispatch({
      type: SET_REVIEW_JOBS_ERROR,
      data
    })
  }
};
export const addReviewJobs = (reviewJobs, payload) => {
  return dispatch => {
    dispatch({
      type: ADD_REVIEW_JOB,
      data: reviewJobs,
      payload
    });
    return true;
  }
};
