import {API_URL} from "../../../configs/constants";
import axios from "axios"
import {getErrorMessage} from "../../../utility/helpers";
import {setFetchMessage} from "../notification";

export const LOGIN_WITH_JWT = 'LOGIN_WITH_JWT';
export const LOGIN_WITH_JWT_SUCCESS = 'LOGIN_WITH_JWT_SUCCESS';
export const LOGIN_WITH_JWT_FAIL = 'LOGIN_WITH_JWT_FAIL';
export const LOGOUT_WITH_JWT = 'LOGOUT_WITH_JWT';
export const RESET_FETCH_AUTH_ERROR = 'RESET_FETCH_AUTH_ERROR';

export const loginWithJWT = user => {
  return async dispatch => {
    dispatch({type: LOGIN_WITH_JWT});
    try {
      const response = await axios.post(`${API_URL}/api/auth/login`, {
        email: user.email,
        password: user.password,
        remember_me: user.remember
      });
      dispatch({
        type: LOGIN_WITH_JWT_SUCCESS,
        data: response.data,
        remember: user.remember
      })
    } catch (error) {
      dispatch({type: LOGIN_WITH_JWT_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error),'error'));
    }
  }
};
export const logoutWithJWT = () => {
  return dispatch => dispatch({type: LOGOUT_WITH_JWT});
};

export const changeRole = role => {
  return dispatch => dispatch({type: "CHANGE_ROLE", userRole: role})
}

export const resetForgotError = () => {
  return dispatch => dispatch({type: "RESET_FORGOT_PASSWORD_ERROR"})
}

export const resetResetError = () => {
  return dispatch => dispatch({type: "RESET_RESET_PASSWORD_ERROR"})
}

export const forgotPassword = (data) => {
  return dispatch => {
    axios
      .post(`${API_URL}/api/auth/password/email`, data)
      .then(response => {
        dispatch({
          type: 'FORGOT_PASSWORD',
          message: response.data.message
        })
      })
      .catch(error => {
        dispatch({
          type: 'FORGOT_PASSWORD_ERROR',
          error: error.response
        })
      })
  }

}


export const resetPassword = (data) => {
  return dispatch => {
    axios
      .post(`${API_URL}/api/auth/password/reset`, data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: 'RESET_PASSWORD',
            message: response.data.message
          })
        } else {
          dispatch({
            type: 'RESET_PASSWORD',
            error: response
          })
        }
      })
      .catch(error => {
        dispatch({
          type: 'RESET_PASSWORD_ERROR',
          error: error.response
        })
      })
  }

};

export const resetFetchError = () => {
  return dispatch => {
    dispatch({
      type: RESET_FETCH_AUTH_ERROR,
    })
  }
};
