import {
  GET_PROFILE_NOTES,
  GET_PROFILE_NOTES_SUCCESS,
  GET_PROFILE_NOTES_FAIL,
  ADD_PROFILE_NOTE,
  ADD_PROFILE_NOTE_SUCCESS,
  ADD_PROFILE_NOTE_FAIL
} from '../../actions/note'

const initialState = {
  loading: false,
  notes: [],
  notEmpty: {}
};

const notesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_NOTES:
      return {...state, loading: true};
    case GET_PROFILE_NOTES_SUCCESS:
      return {...state, loading: false, notes: action.data};
    case GET_PROFILE_NOTES_FAIL:
      return {...state, loading: false};
    case ADD_PROFILE_NOTE:
    case ADD_PROFILE_NOTE_FAIL:
      return {...state};
    case ADD_PROFILE_NOTE_SUCCESS:
      return {
        ...state,
        notes: [action.data, ...state.notes],
        notEmpty: {...state.notEmpty, [action.data.profileId]: true}
      };
    default:
      return state
  }
};

export default notesReducer;
