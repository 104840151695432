import React from "react";
import * as Icon from "react-feather";

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20}/>,
    permissions: ["Administrator", "Writer", "Manager"],
    navLink: "/",
  },
  {
    id: "stats",
    title: "Stats",
    type: "collapse",
    icon: <Icon.Activity size={20}/>,
    permissions: ["Administrator"],
    children: [
      {
        id: "writer",
        title: "Writer",
        type: "item",
        icon: <Icon.Edit size={14}/>,
        permissions: ["Administrator"],
        navLink: "/stats/writer",
      },
      {
        id: "supervisor",
        title: "Manager",
        type: "item",
        icon: <Icon.UserCheck size={14}/>,
        permissions: ["Administrator"],
        navLink: "/stats/supervisor",
      },
      {
        id: "administrator",
        title: "Administrator",
        type: "item",
        icon: <Icon.UserPlus size={14}/>,
        permissions: ["Administrator"],
        navLink: "/stats/administrator",
      },
      {
        id: "finance",
        title: "Fin stats",
        type: "item",
        icon: <Icon.Activity size={14}/>,
        permissions: ["Administrator"],
        navLink: "/stats/finance",
      }
    ]
  },
  {
    groupTitle: 'Apps',
    type: 'groupHeader'
  },
  {
    id: "profiles",
    title: "Profiles",
    type: "collapse",
    icon: <Icon.User size={20}/>,
    permissions: ["Administrator", "Manager"],
    children: [
      {
        id: "accounts",
        title: "Accounts",
        type: "item",
        icon: <Icon.Activity size={14}/>,
        permissions: ["Administrator", "Manager"],
        navLink: "/profiles/accounts",
      },
      {
        id: "freezer",
        title: "Freezer",
        type: "item",
        icon: <Icon.CloudSnow size={14}/>,
        permissions: ["Administrator", "Manager"],
        navLink: "/profiles/freezer",
      }
    ]
  },
  {
    id: "salesCampaigns",
    title: "Sales Campaigns",
    type: "collapse",
    icon: <Icon.ShoppingCart size={20}/>,
    permissions: ["Administrator", "Manager"],
    children: [
      {
        id: "salesWorkflow",
        title: "Sales Workflow",
        type: "item",
        icon: <Icon.CheckSquare size={14}/>,
        permissions: ["Administrator", "Manager"],
        navLink: "/sales-campaigns/workflow",
      },
      {
        id: "salesConfirmation",
        title: "Sales Confirmation",
        type: "item",
        icon: <Icon.CheckSquare size={14}/>,
        permissions: ["Administrator", "Manager"],
        navLink: "/sales-campaigns/confirmation",
      },
      {
        id: "salesDone",
        title: "Sales Done",
        type: "item",
        icon: <Icon.CheckSquare size={14}/>,
        permissions: ["Administrator", "Manager"],
        navLink: "/sales-campaigns/done",
      },
    ]
  },
  {
    id: "reviewCampaigns",
    title: "Review Campaigns",
    type: "collapse",
    icon: <Icon.ShoppingCart size={20}/>,
    permissions: ["Administrator", "Manager"],
    children: [
      {
        id: "reviewWorkflow",
        title: "Review Workflows",
        type: "item",
        icon: <Icon.CheckSquare size={14}/>,
        permissions: ["Administrator", "Manager"],
        navLink: "/review-campaigns/workflow",
      },
      {
        id: "reviewConfirmation",
        title: "Review Confirmation",
        type: "item",
        icon: <Icon.CheckSquare size={14}/>,
        permissions: ["Administrator", "Manager"],
        navLink: "/review-campaigns/confirmation",
      }
    ]
  },
  {
    id: "reviews",
    title: "Reviews",
    type: "collapse",
    icon: <Icon.CheckSquare size={20}/>,
    permissions: ["Administrator", "Writer", "Manager"],
    children: [
      {
        id: "pre_writing",
        title: "Pre-writing",
        type: "item",
        icon: <Icon.CheckSquare size={14}/>,
        permissions: ["Administrator", "Writer", "Manager"],
        navLink: "/reviews/pre-writing",
      },
      {
        id: "ab",
        title: "AB Reviews",
        type: "item",
        icon: <Icon.CheckSquare size={14}/>,
        permissions: ["Administrator", "Manager"],
        navLink: "/reviews/ab",
      },
      {
        id: "posting5stars",
        title: "Posting 5 Stars",
        type: "item",
        icon: <Icon.CheckSquare size={14}/>,
        permissions: ["Administrator", "Manager"],
        navLink: "/reviews/posting-5-stars",
      },
      {
        id: "review_done",
        title: "Done",
        type: "item",
        icon: <Icon.CheckSquare size={14}/>,
        permissions: ["Administrator", "Manager"],
        navLink: "/reviews/done",
      },
    ]
  },
  {
    id: "manage_data",
    title: "Manage Data",
    type: "collapse",
    icon: <Icon.Calendar size={20}/>,
    permissions: ["Administrator", "Manager"],
    children: [
      {
        id: 'userData',
        title: 'User Data',
        type: 'item',
        icon: <Icon.User size={14}/>,
        navLink: "/manage/users",
        permissions: ["Administrator", "Manager"],

      },
      {
        id: 'wishlist',
        title: 'Wishlist',
        type: 'item',
        icon: <Icon.Gift size={14}/>,
        navLink: "/manage/wishlist",
        permissions: ["Administrator", "Manager"],

      },
      {
        id: 'domains',
        title: 'Catch-all Domains',
        type: 'item',
        icon: <Icon.Globe size={14}/>,
        navLink: "/manage/domains",
        permissions: ["Administrator", "Manager"],

      },
      {
        id: 'lockers',
        title: 'Amazon Lockers',
        type: 'item',
        icon: <Icon.Home size={14}/>,
        navLink: "/manage/lockers",
        permissions: ["Administrator", "Manager"],

      },
      {
        id: 'lockersCodeFeed',
        title: 'Lockers Code Feed',
        type: 'item',
        icon: <Icon.Home size={14}/>,
        navLink: "/manage/lockers-code-feed",
        permissions: ["Administrator", "Manager"],

      },
      {
        id: 'testerAddress',
        title: 'Tester Address',
        type: 'item',
        icon: <Icon.Mail size={14}/>,
        navLink: "/manage/tester-address",
        permissions: ["Administrator", "Manager"],

      }
    ]
  },
  {
    id: "settings",
    title: "Settings",
    type: "collapse",
    icon: <Icon.Settings size={20}/>,
    children: [
      {
        id: "users",
        title: "Users",
        type: "item",
        icon: <Icon.Users size={12}/>,
        navLink: "/settings/users",
        permissions: ["Administrator"],

      },
      {
        id: "logout",
        title: "Logout",
        type: "item",
        icon: <Icon.LogOut size={12}/>,
        navLink: "/auth/logout"
      }
    ]
  },

];

export default navigationConfig
