import {LOGIN_WITH_JWT, LOGOUT_WITH_JWT} from "../../actions/auth/loginActions";
import {
  LOCK_REVIEW,
  LOCK_REVIEW_SUCCESS,
  LOCK_REVIEW_FAIL,
  UNLOCK_REVIEW,
  UNLOCK_REVIEW_SUCCESS,
  UNLOCK_REVIEW_FAIL,
  GET_REVIEWS,
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_FAIL,
  SEND_REVIEW_NEXT,
  SEND_REVIEW_NEXT_SUCCESS,
  SEND_REVIEW_NEXT_FAIL,
  UPDATE_REVIEW,
  UPDATE_REVIEW_SUCCESS,
  UPDATE_REVIEW_FAIL,
  BANNED_PROFILE,
  BANNED_PROFILE_SUCCESS,
  BANNED_PROFILE_FAIL,
  APPROVED_PROFILE,
  APPROVED_PROFILE_SUCCESS,
  APPROVED_PROFILE_FAIL,
  EDIT_REVIEW_DELIVERY_SUCCESS,
  AB_REVIEW_APPROVE,
  AB_REVIEW_APPROVE_SUCCESS,
  AB_REVIEW_APPROVE_FAIL,
  BANNED_REVIEW_JOBS,
  BANNED_REVIEW_JOBS_SUCCESS,
  BANNED_REVIEW_JOBS_FAIL,
  BANNED_REVIEW_PROFILE,
  BANNED_REVIEW_PROFILE_SUCCESS,
  BANNED_REVIEW_PROFILE_FAIL
} from "../../actions/reviews";
import {refreshProfiles, checkIsLockedListRow, normalizedReviews} from "../../../utility/helpers";

const initialState = {
  loading: true,
  loadingMap: {},
  reviews: [],
  pagination: {},

};

const reviewsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_REVIEWS:
      return {...state, loading: true,};
    case GET_REVIEWS_SUCCESS:
      return {
        ...state,
        loading: false,
        reviews: checkIsLockedListRow(normalizedReviews(action.data, action.reviewsTypeId), action.userId),
        pagination: action.pagination
      };
    case GET_REVIEWS_FAIL:
      return {...state, loading: false};
    case LOCK_REVIEW:
    case UNLOCK_REVIEW:
    case BANNED_PROFILE:
    case APPROVED_PROFILE:
    case SEND_REVIEW_NEXT:
    case AB_REVIEW_APPROVE:
    case UPDATE_REVIEW:
      return {...state, loadingMap: {...state.loadingMap, [action.payload.profileId]: true}};
    case LOCK_REVIEW_SUCCESS:
    case UNLOCK_REVIEW_SUCCESS:
      let {profileId, locked, isSales} = action.payload;
      return {
        ...state,
        reviews: refreshProfiles(state.reviews, profileId, {...action.data, locked}),
        loadingMap: {...state.loadingMap, [profileId]: false},
      };
    case BANNED_PROFILE_SUCCESS:
    case APPROVED_PROFILE_SUCCESS:
    case SEND_REVIEW_NEXT_SUCCESS:
    case AB_REVIEW_APPROVE_SUCCESS:
      return {
        ...state,
        loadingMap: {...state.loadingMap, [action.payload.profileId]: false},
        reviews: state.reviews.filter(item => item.id !== action.payload.profileId),
      };
    case  UPDATE_REVIEW_SUCCESS:
      return {
        ...state,
        reviews: refreshProfiles(state.reviews, action.payload.profileId, action.data),
        loadingMap: {...state.loadingMap, [action.data.id]: false},
      };
    case LOCK_REVIEW_FAIL:
    case UNLOCK_REVIEW_FAIL:
    case BANNED_PROFILE_FAIL:
    case APPROVED_PROFILE_FAIL:
    case SEND_REVIEW_NEXT_FAIL:
    case AB_REVIEW_APPROVE_FAIL:
    case UPDATE_REVIEW_FAIL:
      return {...state, loadingMap: {...state.loadingMap, [action.payload.profileId]: false}};

    case EDIT_REVIEW_DELIVERY_SUCCESS:
      return {
        ...state, reviews: [
          ...state.reviews.map(review => {
            if (review.profileId === action.data.profileId) {
              return {...review, ...action.data}
            }
            return review
          })
        ]
      };
    case BANNED_REVIEW_JOBS:
    case BANNED_REVIEW_PROFILE:
      return {
        ...state,
        loadingMap: {...state.loadingMap, [action.data.profileId]: true}
      };
    case BANNED_REVIEW_PROFILE_SUCCESS:
    case BANNED_REVIEW_JOBS_SUCCESS:
      return {
        ...state,
        reviews: refreshProfiles(state.reviews, action.data[action.payload.isSales ? 'salesProfileId' : 'profileId'], {...action.payload}),
        loadingMap: {...state.loadingMap, [action.data[action.payload.isSales ? 'salesProfileId' : 'profileId']]: false}
      };
    case BANNED_REVIEW_JOBS_FAIL:
    case BANNED_REVIEW_PROFILE_FAIL:
      return {
        ...state,
        loadingMap: {...state.loadingMap, [action.data.profileId]: false}
      };
    case LOGOUT_WITH_JWT:
    case LOGIN_WITH_JWT: {
      return initialState;
    }
    default:
      return state
  }
};

export default reviewsReducer;
