import {GET_PUBLIC_IP, GET_PUBLIC_IP_SUCCESS, GET_PUBLIC_IP_FAIL, SET_MODEM} from "../../actions/modem";

const initialState = {
  modem: {},
  loading: false,
  loadingMap: {},
};

const modemReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_PUBLIC_IP:
        return {...state, loading: true, loadingMap: {...state.loadingMap, [action.profileId]: true}};
      case GET_PUBLIC_IP_SUCCESS:
        return {
          ...state,
          loading: false,
          loadingMap: {...state.loadingMap, [action.profileId]: false},
          modem: {...state.modem, ...action.data}
        };
      case GET_PUBLIC_IP_FAIL:
        return {...state, loading: false, loadingMap: {...state.loadingMap, [action.profileId]: false}};
      case SET_MODEM:
        return {
          ...state,
          modem: {...state.modem, ...action.modem}
        };
      default:
        return state
    }
  }
;

export default modemReducer;
