import axios from "axios";
import {API_URL, PER_PAGE} from "../../../configs/constants";
import humps from "humps";
import {setFetchMessage} from "../notification";
import {getErrorMessage, isError} from "../../../utility/helpers";

export const GET_LOGS = "GET_LOGS";
export const GET_LOGS_SUCCESS = "GET_LOGS_SUCCESS";
export const GET_LOGS_FAIL = "GET_LOGS_FAIL";
export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAIL = "GET_USERS_FAIL";
export const GET_USER_ROLES = "GET_USER_ROLES";
export const GET_USER_ROLES_SUCCESS = "GET_USER_ROLES_SUCCESS";
export const GET_USER_ROLES_FAIL = "GET_USER_ROLES_FAIL";
export const ADD_USER = "ADD_USER";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";
export const ADD_USER_FAIL = "ADD_USER_FAIL";
export const REMOVE_USER = "REMOVE_USER";
export const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS";
export const REMOVE_USER_FAIL = "REMOVE_USER_FAIL";
export const CHANGE_USER_PARAM = "CHANGE_USER_PARAM";
export const CHANGE_USER_PARAM_SUCCESS = "CHANGE_USER_PARAM_SUCCESS";
export const CHANGE_USER_PARAM_FAIL = "CHANGE_USER_PARAM_FAIL";
export const GET_MODEMS = "GET_MODEMS";
export const GET_MODEMS_SUCCESS = "GET_MODEMS_SUCCESS";
export const GET_MODEMS_FAIL = "GET_MODEMS_FAIL";

const typesLogs = {
  1: {path: "proxy", name: 'proxy'},
  2: {path: "tj", name: 'tj'},
  3: {path: "campaign", name: 'campaign'},
};

const typesUserParams = {
  1: {path: "update-password", name: 'tj'},
  2: {path: "update-role", name: 'proxy'},
  3: {path: "update-proxy-modem", name: 'campaign'},
};

const defaultParams = {
  page: 1,
  perPage: PER_PAGE,
};

export const getLogs = (logsTypeId, paginationData = defaultParams) => {
  let payload = typesLogs[logsTypeId];
  return async dispatch => {
    dispatch({type: GET_LOGS});
    try {
      const response = await axios.get(`${API_URL}/api/settings/logs/${payload.path}/list`, {
        params: paginationData || {}
      });
      isError(response);
      let {data, ...rest} = response.data.pagination;
      payload.pagination = rest;
      dispatch({
        type: GET_LOGS_SUCCESS,
        data,
        payload
      });
    } catch (error) {
      dispatch({type: GET_LOGS_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const getUserRoles = () => {
  return async dispatch => {
    dispatch({type: GET_USER_ROLES});
    try {
      const response = await axios.get(`${API_URL}​/api/settings/user/role/list`);
      isError(response);
      dispatch({
        type: GET_USER_ROLES_SUCCESS,
        data: response.data.roles,
      });
    } catch (error) {
      dispatch({type: GET_USER_ROLES_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const getUsers = () => {
  return async dispatch => {
    dispatch({type: GET_USERS});
    try {
      const response = await axios.get(`${API_URL}/api/settings/user/list`);
      isError(response);
      dispatch({
        type: GET_USERS_SUCCESS,
        data: response.data.users,
      });
    } catch (error) {
      dispatch({type: GET_USERS_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const addUser = (data) => {
  return async dispatch => {
    dispatch({type: ADD_USER});
    try {
      const response = await axios.post(`${API_URL}/api/settings/user/add`, humps.decamelizeKeys(data));
      isError(response);
      dispatch({
        type: ADD_USER_SUCCESS,
        data: response.data.user[0],
      });
      return response;
    } catch (error) {
      dispatch({type: ADD_USER_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const removeUser = (data) => {
  return async dispatch => {
    dispatch({type: REMOVE_USER});
    try {
      const response = await axios.delete(`${API_URL}/api/settings/user/${data.id}`);
      isError(response);
      dispatch({
        type: REMOVE_USER_SUCCESS,
        data,
      });
      return response;
    } catch (error) {
      dispatch({type: REMOVE_USER_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const changeUserParam = (data, payload, paramTypeId) => {
  return async dispatch => {
    dispatch({type: CHANGE_USER_PARAM, payload});
    try {
      const response = await axios.post(`${API_URL}/api/settings/user/${typesUserParams[paramTypeId].path}`, humps.decamelizeKeys(data));
      isError(response);
      dispatch({
        type: CHANGE_USER_PARAM_SUCCESS,
        data: response.data,
        payload
      });
      return response;
    } catch (error) {
      dispatch({type: CHANGE_USER_PARAM_FAIL, payload});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const getModems = () => {
  return async dispatch => {
    dispatch({type: GET_MODEMS});
    try {
      const response = await axios.get(`${API_URL}/api/settings/proxy-modem/list`);
      isError(response);
      dispatch({
        type: GET_MODEMS_SUCCESS,
        data: humps.camelizeKeys(response.data),
      });
    } catch (error) {
      dispatch({type: GET_MODEMS_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
