import humps from 'humps';
import {LOGIN_WITH_JWT, LOGOUT_WITH_JWT} from "../../actions/auth/loginActions";
import {
  GET_PROFILES,
  GET_PROFILES_SUCCESS,
  GET_PROFILES_FAIL,
  LOCK_PROFILE,
  LOCK_PROFILE_SUCCESS,
  LOCK_PROFILE_FAIL,
  UNLOCK_PROFILE,
  UNLOCK_PROFILE_SUCCESS,
  UNLOCK_PROFILE_FAIL,
  SAVE_AMZ_ACCOUNT,
  SAVE_AMZ_ACCOUNT_SUCCESS,
  SAVE_AMZ_ACCOUNT_FAIL,
  CREATE_MANUAL_PROFILE,
  CREATE_MANUAL_PROFILE_SUCCESS,
  CREATE_MANUAL_PROFILE_FAIL,
  GENERATE_PROFILES,
  GENERATE_PROFILES_SUCCESS,
  GENERATE_PROFILES_FAIL,
  REMOVE_PROFILE,
  REMOVE_PROFILE_SUCCESS,
  REMOVE_PROFILE_FAIL
} from "../../actions/profiles";
import {refreshProfiles, checkIsLockedListRow} from "../../../utility/helpers";

const initialState = {
  profiles: [],
  loadingMap: {},
  loading: false,
};

const profilesReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_PROFILES:
      case SAVE_AMZ_ACCOUNT:
      case CREATE_MANUAL_PROFILE:
      case REMOVE_PROFILE:
      case GENERATE_PROFILES:
        return {...state, loading: true};
      case GET_PROFILES_SUCCESS:
        return {
          ...state,
          loading: false,
          profiles: checkIsLockedListRow(humps.camelizeKeys(action.data), action.payload.userId)
        };
      case SAVE_AMZ_ACCOUNT_SUCCESS:
      case REMOVE_PROFILE_SUCCESS:
        return {
          ...state,
          loading: false,
          profiles: state.profiles.filter((profile => profile.id !== action.id)),
        };
      case CREATE_MANUAL_PROFILE_SUCCESS:
        return {...state, loading: false, profiles: [...state.profiles, action.data]};
      case GENERATE_PROFILES_SUCCESS:
        return {...state, loading: false, profiles: [...state.profiles, ...action.data]};
      case GET_PROFILES_FAIL:
      case SAVE_AMZ_ACCOUNT_FAIL:
      case CREATE_MANUAL_PROFILE_FAIL:
      case REMOVE_PROFILE_FAIL:
      case GENERATE_PROFILES_FAIL:
        return {...state, loading: false};
      case LOCK_PROFILE:
      case UNLOCK_PROFILE:
        return {...state};
      case LOCK_PROFILE_SUCCESS:
      case UNLOCK_PROFILE_SUCCESS:
        let {profileId, locked} = action.payload;
        return {
          ...state,
          profiles: refreshProfiles(state.profiles, profileId, {...action.data, locked})
        };
      case LOCK_PROFILE_FAIL:
      case UNLOCK_PROFILE_FAIL:
        return {...state};
      case LOGOUT_WITH_JWT:
      case LOGIN_WITH_JWT: {
        return initialState;
      }
      default:
        return state
    }
  }
;

export default profilesReducer;
