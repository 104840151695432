import axios from "axios";
import {API_URL} from "../../../configs/constants";
import {getErrorMessage, isError, normalizedSalesProfile} from "../../../utility/helpers";
import {setFetchMessage} from "../notification";
import humps from "humps";
import {setModem} from "../modem";
import notices from "../../../utility/notices";

export const GET_REVIEWS = "GET_REVIEWS";
export const GET_REVIEWS_SUCCESS = "GET_REVIEWS_SUCCESS";
export const GET_REVIEWS_FAIL = "GET_REVIEWS_FAIL";
export const UPDATE_REVIEW = "UPDATE_REVIEW";
export const UPDATE_REVIEW_SUCCESS = "UPDATE_REVIEW_SUCCESS";
export const UPDATE_REVIEW_FAIL = "UPDATE_REVIEW_FAIL";
export const LOCK_REVIEW = "LOCK_REVIEW";
export const LOCK_REVIEW_SUCCESS = "LOCK_REVIEW_SUCCESS";
export const LOCK_REVIEW_FAIL = "LOCK_REVIEW_FAIL";
export const SEND_REVIEW_NEXT = "SEND_REVIEW_NEXT";
export const SEND_REVIEW_NEXT_SUCCESS = "SEND_REVIEW_NEXT_SUCCESS";
export const SEND_REVIEW_NEXT_FAIL = "SEND_REVIEW_NEXT_FAIL";
export const AB_REVIEW_APPROVE = "AB_REVIEW_APPROVE";
export const AB_REVIEW_APPROVE_SUCCESS = "AB_REVIEW_APPROVE_SUCCESS";
export const AB_REVIEW_APPROVE_FAIL = "AB_REVIEW_APPROVE_FAIL";
export const UNLOCK_REVIEW = "UNLOCK_REVIEW";
export const UNLOCK_REVIEW_SUCCESS = "UNLOCK_REVIEW_SUCCESS";
export const UNLOCK_REVIEW_FAIL = "UNLOCK_REVIEW_FAIL";
export const BANNED_PROFILE = "BANNED_PROFILE";
export const BANNED_PROFILE_SUCCESS = "BANNED_PROFILE_SUCCESS";
export const BANNED_PROFILE_FAIL = "BANNED_PROFILE_FAIL";
export const APPROVED_PROFILE = "APPROVED_PROFILE";
export const APPROVED_PROFILE_SUCCESS = "APPROVED_PROFILE_SUCCESS";
export const APPROVED_PROFILE_FAIL = "APPROVED_PROFILE_FAIL";
export const EDIT_REVIEW_DELIVERY = "EDIT_REVIEW_DELIVERY";
export const EDIT_REVIEW_DELIVERY_SUCCESS = "EDIT_REVIEW_DELIVERY_SUCCESS";
export const EDIT_REVIEW_DELIVERY_FAIL = "EDIT_REVIEW_DELIVERY_FAIL";
export const BANNED_REVIEW_PROFILE = "BANNED_REVIEW_PROFILE";
export const BANNED_REVIEW_PROFILE_SUCCESS = "BANNED_REVIEW_PROFILE_SUCCESS";
export const BANNED_REVIEW_PROFILE_FAIL = "BANNED_REVIEW_PROFILE_FAIL";
export const BANNED_REVIEW_JOBS = "BANNED_REVIEW_JOBS";
export const BANNED_REVIEW_JOBS_SUCCESS = "BANNED_REVIEW_JOBS_SUCCESS";
export const BANNED_REVIEW_JOBS_FAIL = "BANNED_REVIEW_JOBS_FAIL";

export const typesReview = {
  0: {path: "review-job/confirmation", name: 'confirmation'},
  1: {path: 'amazon-basic-review/confirmed', name: 'ab'},
  2: {path: "review-job/done", name: 'done'},
  3: {path: "review-job/prewrite", name: 'prewrite'},
  4: {path: 'amazon-basic-review', name: 'ab'},
  5: {path: "review-job/posting-5-stars", name: 'posting5'},
  6: {path: "review-job/donebanned", name: 'donebanned'},
  7: {path: "sales/profile/done", name: 'done'},
  8: {path: "sales/profile/donebanned", name: 'donebanned'},
  9: {path: "sales/profile/confirmation", name: 'confirmation'},
  // 9: {path: "review-job/confirmation", name: 'confirmation'},

};

export const getReviews = (reviewsTypeId, {perPage, ...paginationData} = {}) => {
  return async (dispatch, getState) => {
    const state = getState();
    let path = typesReview[reviewsTypeId].path,
      userId = state.auth.login.user.id,
      response;

    dispatch({type: GET_REVIEWS});
    try {
      if ([0, 9].includes(reviewsTypeId)) {
        response = await axios.get(`${API_URL}/api/${path}/list`);
      } else {
        response = await axios.post(`${API_URL}/api/${path}/list`, {
          ...humps.decamelizeKeys(paginationData),
          perPage
        });
      }
      isError(response);
      let {pagination: {data, ...rest} = {}, profiles, salesProfiles} = humps.camelizeKeys(response.data);
      dispatch({
        type: GET_REVIEWS_SUCCESS,
        data: data || profiles || (salesProfiles && salesProfiles.map(salesProfile => normalizedSalesProfile(salesProfile))),
        pagination: humps.camelizeKeys(rest || {}),
        userId,
        reviewsTypeId
      });
    } catch (error) {
      dispatch({type: GET_REVIEWS_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const updateReview = (data, review) => {
  const payload = {profileId: review.id};
  return async dispatch => {
    dispatch({type: UPDATE_REVIEW, payload});
    try {
      const response = await axios.post(`${API_URL}/api/review-job/update`, humps.decamelizeKeys(data));
      isError(response);
      dispatch({
        type: UPDATE_REVIEW_SUCCESS,
        data: humps.camelizeKeys(response.data),
        payload
      });
      return response
    } catch (error) {
      dispatch({type: UPDATE_REVIEW_FAIL, payload});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const sendReviewNext = (data, reviewsTypeId, profileId) => {

  let payload = {profileId};
  return async dispatch => {
    dispatch({type: SEND_REVIEW_NEXT, payload});
    try {
      const response = await axios.post(`${API_URL}/api/${typesReview[reviewsTypeId].path}-written`, humps.decamelizeKeys(data));
      isError(response);
      dispatch({
        type: SEND_REVIEW_NEXT_SUCCESS,
        data: response.data.profiles,
        payload
      });
      return response
    } catch (error) {
      dispatch({type: SEND_REVIEW_NEXT_FAIL, payload});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const abReviewApprove = (data) => {
  let payload = {profileId: data.profileId};
  return async dispatch => {
    dispatch({type: AB_REVIEW_APPROVE, payload});
    try {
      const response = await axios.post(`${API_URL}/api/amazon-basic-review/approve`, humps.decamelizeKeys(data));
      isError(response);

      dispatch({
        type: AB_REVIEW_APPROVE_SUCCESS,
        payload
      });
      return response
    } catch (error) {
      dispatch({type: AB_REVIEW_APPROVE_FAIL, payload});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const lockReview = (profileId, reviewsTypeId, isSales) => {
  let payload = {...typesReview[reviewsTypeId], profileId};
  return async dispatch => {
    dispatch({type: LOCK_REVIEW, payload});
    try {
      const response = await axios.get(`${API_URL}/api/${typesReview[reviewsTypeId].path}${isSales ? '' : '/profile'}/${profileId}`);
      // isError(response);
      const result = humps.camelizeKeys(response.data);
      const {profile = {}, salesProfile, isNeedUpdateProxyModemIp, proxy = {}, allowWorkWithoutProxy} = result;
      dispatch({
        type: LOCK_REVIEW_SUCCESS,
        data: isSales ? normalizedSalesProfile(salesProfile) : profile,
        payload: {...payload, profileId: profileId, locked: response.status === 205, isSales}
      });
      if (reviewsTypeId === 5) {
        dispatch(setModem(proxy, profile.id, isNeedUpdateProxyModemIp, allowWorkWithoutProxy));
      }
      return response
    } catch (error) {
      dispatch({type: LOCK_REVIEW_FAIL, payload});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const unlockReview = (profileId, reviewsTypeId) => {
  let payload = {...typesReview[reviewsTypeId], profileId};
  return async dispatch => {
    dispatch({type: UNLOCK_REVIEW});
    try {
      await axios.post(`${API_URL}/api/review-job/unlock`, {"profile_id": profileId});
      // isError(response);
      dispatch({
        type: UNLOCK_REVIEW_SUCCESS,
        payload: {...payload, locked: false}
      });
    } catch (error) {
      dispatch({type: UNLOCK_REVIEW_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const bannedProfile = (data, isSales) => {
  let payload = {profileId: data[isSales ? 'salesProfileId' : 'profileId']};
  const isABR = data.amazonBasicReviewId;
  return async dispatch => {
    dispatch({type: BANNED_PROFILE, payload});
    try {
      const response = await axios.post(`${API_URL}/api/${isABR ? 'amazon-basic-review' : isSales ? 'sales/profile' : 'profile'}/banned`, humps.decamelizeKeys(data));
      isError(response);
      dispatch({
        type: BANNED_PROFILE_SUCCESS,
        payload
      });
    } catch (error) {
      dispatch({type: BANNED_PROFILE_FAIL, payload});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const approvedProfile = (profileId, isSales) => {
  let payload = {profileId};
  return async dispatch => {
    dispatch({type: APPROVED_PROFILE, payload});
    try {
      const response = await axios.post(`${API_URL}/api/${isSales ? 'sales/' : ''}profile/approve`, {[`${isSales ? 'sales_' : ''}profile_id`]: profileId});
      isError(response);
      dispatch({
        type: APPROVED_PROFILE_SUCCESS,
        payload
      });
      dispatch(setFetchMessage(notices.orderApprove, 'success'));
      return response
    } catch (error) {
      dispatch({type: APPROVED_PROFILE_FAIL, payload});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const editReviewDelivery = (data) => {
  return async dispatch => {
    dispatch({type: EDIT_REVIEW_DELIVERY});
    try {
      const response = await axios.post(`${API_URL}/api/review-job/update/delivery-date`, humps.decamelizeKeys(data));
      isError(response);
      dispatch({
        type: EDIT_REVIEW_DELIVERY_SUCCESS, data: humps.camelizeKeys(response.data)
      });
      return response
    } catch (error) {
      dispatch({type: EDIT_REVIEW_DELIVERY_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const getAsinReviews = (data) => {
  return async dispatch => {
    try {
      const response = await axios.post(`${API_URL}/api/review-job/done/list/product`, data);
      isError(response);
      return humps.camelizeKeys(response?.data?.pagination)
    } catch (error) {
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const setBanReviewProfile = (data, payload) => {
  return async dispatch => {
    dispatch({type: BANNED_REVIEW_PROFILE, data});
    try {
      const response = await axios.post(`${API_URL}/api/${payload.isSales ? 'sales/profile/donebanned' : 'review-job/donebanned/profile'}`, humps.decamelizeKeys(data));
      isError(response);
      dispatch({
        type: BANNED_REVIEW_PROFILE_SUCCESS, data, payload
      });
      return response
    } catch (error) {
      dispatch({type: BANNED_REVIEW_PROFILE_FAIL, data});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
export const setBanReviewJobs = (data, payload) => {
  return async dispatch => {
    dispatch({type: BANNED_REVIEW_JOBS, data});
    try {
      const response = await axios.post(`${API_URL}/api/${payload.isSales ? 'sales/profile/donebanned/order' : 'review-job/donebanned'}`, humps.decamelizeKeys(data));
      isError(response);
      dispatch({type: BANNED_REVIEW_JOBS_SUCCESS, data, payload});
      return response
    } catch (error) {
      dispatch({type: BANNED_REVIEW_JOBS_FAIL, data});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

