import {combineReducers} from "redux"
import customizer from "./customizer/"
import auth from "./auth/"
import notificationReducer from "./notification";
import profilesReducer from "./profiles";
import ordersReducer from "./orders";
import reviewsReducer from "./reviews";
import manageReducer from "./manage";
import dashboardReducer from "./dashboard";
import systemReducer from "./system";
import statsReducer from "./stats";
import settingsReducer from "./settings";
import modemReducer from "./modem";
import notesReducer from "./note";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  notification: notificationReducer,
  profiles: profilesReducer,
  orders: ordersReducer,
  reviews: reviewsReducer,
  manage: manageReducer,
  dashboard: dashboardReducer,
  system: systemReducer,
  stats: statsReducer,
  settings: settingsReducer,
  modem: modemReducer,
  notes: notesReducer
});

export default rootReducer
