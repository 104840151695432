import humps from 'humps';
import {
  GET_SYSTEMS,
  GET_SYSTEMS_SUCCESS,
  GET_SYSTEMS_FAIL,
  SET_FBM,
  SET_FBM_SUCCESS,
  SET_FBM_FAIL,
  RESET_FETCH_SYSTEM_ERROR,
  RESET_FETCH_SYSTEM_SUCCESS
} from "../../actions/system";

const initialState = {
  loading: false,
  configs: [],
  fetchError: null,
  fetchSuccess: null,
};

const systemReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SYSTEMS:
      return {...state, loading: true};
    case GET_SYSTEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        configs: humps.camelizeKeys(action.data),
      };
    case GET_SYSTEMS_FAIL:
      return {
        ...state,
        loading: false,
        fetchError: action.error.data,
      };
    case SET_FBM:
      return {...state, loading: true, loadingMap: {...state.loadingMap, ["orderTypeId" + 1]: true}};
    case SET_FBM_SUCCESS:
      let newConfigs = [...state.configs];
      newConfigs[0] = {...newConfigs[0], value: action.data.value};
      return {
        ...state,
        loading: false,
        fetchSuccess: action.data,
        configs: newConfigs,
      };
    case SET_FBM_FAIL:
      return {
        ...state,
        loading: false,
        fetchError: action.error.data,
      };
    case RESET_FETCH_SYSTEM_ERROR:
      return {...state, fetchError: null};
    case RESET_FETCH_SYSTEM_SUCCESS:
      return {...state, fetchSuccess: null};

    default:
      return state
  }
};

export default systemReducer;
