import {toast} from "react-toastify";
import {history} from "../../../history";
import humps from 'humps';
import Cookies from 'js-cookie';

import {
  LOGIN_WITH_JWT,
  LOGIN_WITH_JWT_SUCCESS,
  LOGIN_WITH_JWT_FAIL,
  LOGOUT_WITH_JWT,
  RESET_FETCH_AUTH_ERROR,
} from "../../actions/auth/loginActions";

const initialState = {
  auth: /*null*/ Cookies.get('freshToken'),
  user: localStorage.getItem('rmsUser') ? humps.camelizeKeys(JSON.parse(localStorage.getItem('rmsUser'))) : {},
  loggedInWith: '',
  authError: null,
  fetchError: null,
  authForgotError: null,
  authResetError: null,
  loading: null,
};

export const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_WITH_JWT:
      return {...state, loading: true};
    case LOGIN_WITH_JWT_SUCCESS:

      return {
        ...state,
        loading: false,
        user: humps.camelizeKeys({...action.data, role: action.data.roles[0] || {}}),
        auth: true,
      };
    case LOGIN_WITH_JWT_FAIL:
      return {
        ...state,
        loading: false,
        authError: action.error,
      };
    case LOGOUT_WITH_JWT: {
      return {...initialState, auth: false, user: {}, fetchError: action.error};
    }
    case "CHANGE_ROLE": {
      return {...state, userRole: action.userRole}
    }
    case "FORGOT_PASSWORD": {
      if (action.error)
        toast.error(action.error.data.message);

      if (action.message)
        toast.success(action.message, {
          onClose() {
            history.push('/login')
          }
        });
      return {...state}
    }
    case "FORGOT_PASSWORD_ERROR": {
      return {...state, authForgotError: action.error}
    }
    case "RESET_FORGOT_PASSWORD_ERROR": {
      return {...state, authForgotError: null}
    }
    case "RESET_PASSWORD": {
      if (action.error)
        toast.error(action.error.data.message);

      if (action.message)
        toast.success(action.message, {
          onClose() {
            history.push('/login')
          }
        });
      return {...state}
    }
    case "RESET_PASSWORD_ERROR": {
      return {...state, authResetError: action.error}
    }
    case "RESET_RESET_PASSWORD_ERROR": {
      return {...state, authResetError: null}
    }
    case RESET_FETCH_AUTH_ERROR: {
      return {...state, fetchError: null}
    }
    default: {
      return state
    }
  }
};
