import {isLocalhost} from "./helpers";

export function requireAuth(nextState, replace, next) {
    let auth = false;
    if(isLocalhost){
      if(localStorage.getItem('auth') && localStorage.getItem('jwtToken')){
        auth = true;
      }
    }else{
      if(localStorage.getItem('auth') && localStorage.getItem('user')){
        auth = true;
      }
    }
    return auth;
}
