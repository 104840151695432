import axios from "axios";
import {API_URL} from "../../../configs/constants";

export const GET_SYSTEMS = 'GET_SYSTEMS';
export const GET_SYSTEMS_SUCCESS = 'GET_SYSTEMS_SUCCESS';
export const GET_SYSTEMS_FAIL = 'GET_SYSTEMS_FAIL';
export const SET_FBM = 'SET_FBM';
export const SET_FBM_SUCCESS = 'SET_FBM_SUCCESS';
export const SET_FBM_FAIL = 'SET_FBM_FAIL';
export const RESET_FETCH_SYSTEM_ERROR = 'RESET_FETCH_SYSTEM_ERROR';
export const RESET_FETCH_SYSTEM_SUCCESS = 'RESET_FETCH_SYSTEM_SUCCESS';

export const getSystemList = () => {
  return async dispatch => {
    dispatch({type: GET_SYSTEMS});
    try {
      const response = await axios.get(`${API_URL}/api/settings/system/list`);
      dispatch({
        type: GET_SYSTEMS_SUCCESS,
        data: response.data.configs,
      });
    } catch (error) {
      dispatch({
        type: GET_SYSTEMS_FAIL,
        error: error.response ? error.response : error
      })
    }
  }
};
export const setMaxFBM = (valueFBM) => {
  return async dispatch => {
    dispatch({type: SET_FBM});
    try {
      const response = await axios.post(`${API_URL}/api/settings/system/set-max-fbm`, {"max_fbm": valueFBM});
      dispatch({
        type: SET_FBM_SUCCESS,
        data: {message: "Max FBM changed successfully", value: valueFBM},
      });
      return response;
    } catch (error) {
      dispatch({
        type: SET_FBM_FAIL,
        error: error.response ? error.response : error
      })
    }
  }
};
export const resetFetchSystemError = () => {
  return dispatch => {
    dispatch({
      type: RESET_FETCH_SYSTEM_ERROR,
    })
  }
};
export const resetFetchSystemSuccess = () => {
  return dispatch => {
    dispatch({
      type: RESET_FETCH_SYSTEM_SUCCESS,
    })
  }
};

