import {SET_FETCH_MESSAGE, RESET_FETCH_MESSAGE} from '../../actions/notification'

const initialState = {
  fetchMessage:null
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FETCH_MESSAGE:
      return { ...state, fetchMessage: action.message };
    case RESET_FETCH_MESSAGE:
      return { ...state, fetchMessage: null };
    default:
      return state
  }
};

export default notificationReducer
