import * as moment from "moment-timezone";

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(
    /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
  )
);

export const isError = (response) => {
  if (response.status === 205) {
    throw response
  }
};

export const isValidEmail = (userInput) => userInput.match(/^\S+@\S+\.\S+$/);

export const isValidUrlAMZ = (userInput) => userInput.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g);

export const canBeProcessed = (date, currentDate) => moment(currentDate).isAfter(date);

export const checkIsLockedListRow = (list, userId) => list.map((row) => {
    let {locked, blockedByUserId, updatedAt} = row;
    let diff = moment.tz("Europe/Berlin").diff(moment.tz(updatedAt, "Europe/Berlin"), "minutes");
    if (locked) {
      if (+diff > 5 || blockedByUserId === userId) {
        row.locked = false;
      }
    }
    return row;
  }
);

export const refreshProfiles = (profiles, profileId, values) => [...profiles].map((profile) => {
  if (profile.id === profileId) {
    profile = {...profile, ...values, updatedAt: new Date()};
  }

  return profile;
});

export const getErrorMessage = (error) => {
  const {response, data} = error;
  let message = '';
  if (response && response.data && response.data.message) message = response.data.message;
  if (data && data.message) message = data.message;
  return message;
};

export const getText = (text, full, max) => {
  let finalText = text;
  if (!full && text.length > max) {
    finalText = text.substr(0, max) + '...';
  }
  return finalText
};

export const getAmzSellers = (reviews, defaultAmzSellers = {}, isLock) => {
  let amzSellers = {...defaultAmzSellers};
  reviews.forEach(review => {
    let sellerName = review?.campaign?.product?.amzSellerName || review?.product?.amzSellerName;
    if ((isLock && review.id) || !isLock) amzSellers[sellerName] ? amzSellers[sellerName] += 1 : amzSellers[sellerName] = 1
  });

  return amzSellers;
};

export const normalizedSalesProfile = (profile) => {
  const {salesOrders, ...rest} = profile;
  return {...rest, orders: [{...rest, reviewJobs: salesOrders}]}
};

/*Reviews Helpers*/
export const getLatestDeliveryDate = (dates, isSales) => {
  let moments = [];
  dates.forEach(d => {
    if (d[isSales ? 'orderDate' : 'amazonDeliveryDate'])
      moments.push(moment(d[isSales ? 'orderDate' : 'amazonDeliveryDate']));
  });

  return moments.length ? moment.max(moments) : false;
};

export const getReviewDelayDate = (date, delay) => moment(date).add(delay, "days");

export const getReviewDate = (row, delay, isSales) => {
  const latestDeliveryDate = getLatestDeliveryDate(row.orders.reduce((acc, current) => [...acc, ...current.reviewJobs], []), isSales);
  const reviewDelayDate = getReviewDelayDate(latestDeliveryDate, delay);
  return {
    latestDeliveryDateTitle: moment(latestDeliveryDate).format('DD.MM.YYYY'),
    reviewDelayDateTitle: moment(reviewDelayDate).format('DD.MM.YYYY'),
    latestDeliveryDate,
    reviewDelayDate
  };
};

export const getProdCount = (orders) => {
  let count = 0, maxCount = 0;
  orders.forEach(order => {
    order.reviewJobs.forEach(review => {
      maxCount++;
      if (review.option === "Only_star" || (review.review && review.review.length > 0)) {
        count++;
      }
    })
  });

  return {count, maxCount}
};

export const getReviewFinishData = (state) => ({
  profileId: state.profileId,
  orders: state.orders.map(({id, reviewJobs}) => ({
    orderId: id,
    reviewJobs: reviewJobs.map(({id, option, review, reviewLink}) => ({
      id,
      option: option ? option : '',
      review: review ? review : '',
      reviewLink: reviewLink ? reviewLink : ''
    }))
  }))
});

export const normalizedReviews = (reviews, reviewsTypeId) => reviews.map(review => {
  if (reviewsTypeId === 0) {
    return {...review, ...getReviewDate(review, 4)}
  } else if (reviewsTypeId === 9) {
    return {...review, profileId: review.id, ...getReviewDate(review, 4, true)}
  } else {
    return {...review, id: review[[7, 8].includes(reviewsTypeId) ? 'salesProfileId' : 'profileId']}
  }
});

export const bannedProfileStatuses = ["Done_Banned_Profile_And_Review_Job", "Done_Banned_Profile_And_Order"];
export const bannedProfileJobsStatuses = ["Done_Banned_Review_Job", "Done_Banned_Order"];

/*Reviews Helpers End*/

export const convertDate = (date, reverse) => date && date.split(reverse ? "." : "-").reverse().join(reverse ? "-" : ".");

export const getDate = (type, middleDate, offset, isMaxNow = true) => {
  let result;
  if (type === 'max') {
    result = middleDate ? moment(middleDate).add(offset, 'days').format('YYYY-MM-DD') : isMaxNow ? moment().format('YYYY-MM-DD') : null
  }
  if (type === 'min') {
    result = middleDate ? moment(middleDate).subtract(offset, 'days').format('YYYY-MM-DD') : null
  }
  return result
};
