import humps from 'humps';
import {
  GET_MANAGE_DATA,
  GET_MANAGE_DATA_SUCCESS,
  GET_MANAGE_DATA_FAIL,
  ADD_MANAGE_ITEM_SUCCESS,
  UPDATE_MANAGE_ITEM_SUCCESS,
  DELETE_MANAGE_ITEM_SUCCESS,
  BUY_PROXY,
  BUY_PROXY_FAIL,
  GET_MAIL_STATUS,
  GET_MAIL_STATUS_SUCCESS,
  GET_MAIL_STATUS_FAIL
} from "../../actions/manage/index";
import {LOGIN_WITH_JWT, LOGOUT_WITH_JWT} from "../../actions/auth/loginActions";

const initialState = {
  loading: false,
  loadingMap: {},
  data: {
    profiles: [],
    proxies: [],
    wishlist: [],
    codes: [],
    domains: [],
    testerAddress: [],
    lockers: [],
    lockersCode: [],
    lockersCodeExported: [],
    mailStatus: {}
  },
  pagination: {
    profiles: {},
    proxies: {},
    wishlist: {},
    codes: {},
    domains: {},
    lockers: {},
    lockersCode: {},
    testerAddress: {},
    lockersCodeExported: {},
  },
};

const manageReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MANAGE_DATA:
    case GET_MAIL_STATUS:
      return {...state, loadingMap: {...state.loadingMap, [action.payload.name]: true}};
    case GET_MANAGE_DATA_SUCCESS:
      return {
        ...state,
        loadingMap: {...state.loadingMap, [action.payload.name]: false},
        data: {...state.data, [action.payload.name]: action.data},
        pagination: {...state.pagination, [action.payload.name]: action.payload.pagination || {}}
      };
    case GET_MANAGE_DATA_FAIL:
      return {...state, loadingMap: {...state.loadingMap, [action.payload.name]: false}};

    case  GET_MAIL_STATUS_SUCCESS:
      return {
        ...state,
        data: {...state.data, [action.payload.name]: action.data},
        loadingMap: {...state.loadingMap, [action.payload.name]: false}
      };
    case  GET_MAIL_STATUS_FAIL:
      return {...state, loadingMap: {...state.loadingMap, [action.payload.name]: false},};
    case  ADD_MANAGE_ITEM_SUCCESS:
      return {
        ...state,
        data: {...state.data, [action.payload.name]: [action.data, ...state.data[action.payload.name]]}
      };
    case DELETE_MANAGE_ITEM_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.name]: state.data[action.payload.name].filter(item => item.id !== action.payload.id)
        }
      };
    case UPDATE_MANAGE_ITEM_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.name]: state.data[action.payload.name].map(item => item.id === action.payload.id ? humps.camelizeKeys(action.data) : item)
        }
      };
    case BUY_PROXY:
      return {...state, loading: true};
    case BUY_PROXY_FAIL:
      return {...state, loading: false};
    case LOGOUT_WITH_JWT:
    case LOGIN_WITH_JWT: {
      return initialState;
    }
    default:
      return state
  }
};

export default manageReducer
