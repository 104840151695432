export const SET_FETCH_MESSAGE = 'SET_FETCH_MESSAGE';
export const RESET_FETCH_MESSAGE = 'RESET_FETCH_MESSAGE';

export const setFetchMessage = (message, type, options) => {
  return dispatch => {
    dispatch({
      type: SET_FETCH_MESSAGE,
      message: {message, type, options}
    })
  }
};
export const resetFetchMessage = () => {
  return dispatch => {
    dispatch({
      type: RESET_FETCH_MESSAGE,
    })
  }
};

