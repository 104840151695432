import axios from "axios";
import humps from 'humps';
import {API_URL} from "../../../configs/constants";
import {setFetchMessage} from "../notification";
import {isError, getErrorMessage} from "../../../utility/helpers";

export const GET_DASH_STATS = "GET_DASH_STATS";
export const GET_DASH_STATS_SUCCESS = 'GET_DASH_STATS_SUCCESS';
export const GET_DASH_STATS_FAIL = 'GET_DASH_STATS_FAIL';
export const GET_DASH_CHART = "GET_DASH_CHART";
export const GET_DASH_CHART_SUCCESS = 'GET_DASH_CHART_SUCCESS';
export const GET_DASH_CHART_FAIL = 'GET_DASH_CHART_FAIL';

export const getStatsData = () => {
  return async dispatch => {
    dispatch({type: GET_DASH_STATS});
    try {
      const response = await axios.get(`${API_URL}/api/dashboard/stats`);
      isError(response);
      dispatch({
        type: GET_DASH_STATS_SUCCESS,
        data: humps.camelizeKeys(response.data)
      })
    } catch (error) {
      dispatch({type: GET_DASH_STATS_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const getChartData = (data) => {
  return async dispatch => {
    dispatch({type: GET_DASH_CHART});
    try {
      const response = await axios.post(`${API_URL}/api/dashboard/chart-data`, {...humps.decamelizeKeys(data)});
      isError(response);
      const result = response.data.results;
      Object.keys(result).forEach(key => {
        result[key] = humps.camelizeKeys(result[key]);
      });
      dispatch({
        type: GET_DASH_CHART_SUCCESS,
        data: result
      })
    } catch (error) {
      dispatch({type: GET_DASH_CHART_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
