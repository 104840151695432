import humps from 'humps';
import {
  ORDER_LOCK,
  ORDER_LOCK_SUCCESS,
  ORDER_LOCK_FAIL,
  ORDER_LOCK_PRODUCTS,
  ORDER_LOCK_PRODUCTS_SUCCESS,
  ORDER_LOCK_PRODUCTS_FAIL,
  ORDER_LOCK_GIFT,
  ORDER_LOCK_GIFT_SUCCESS,
  ORDER_LOCK_GIFT_FAIL,
  LOCK_REVIEW_JOB,
  LOCK_REVIEW_JOB_SUCCESS,
  LOCK_REVIEW_JOB_FAIL,
  UNLOCK_REVIEW_JOB,
  UNLOCK_REVIEW_JOB_SUCCESS,
  UNLOCK_REVIEW_JOB_FAIL,
  REMOVE_REVIEW_JOB,
  REMOVE_REVIEW_JOB_SUCCESS,
  REMOVE_REVIEW_JOB_FAIL,
  GET_PRODUCT,
  GET_PRODUCT_SUCCESS,
  GET_PRODUCT_FAIL,
  GET_RANDOM_PRODUCT,
  GET_RANDOM_PRODUCT_SUCCESS,
  GET_RANDOM_PRODUCT_FAIL,
  ORDER_FINISH,
  ORDER_FINISH_SUCCESS,
  ORDER_FINISH_FAIL,
  SET_ACTIVE_ORDER,
  RESET_FETCH_ERROR,
  RESET_FETCH_SUCCESS,
  GET_ACCOUNTS,
  GET_ACCOUNTS_SUCCESS,
  GET_ACCOUNTS_FAIL,
  GET_CAMPAIGNS,
  GET_CAMPAIGNS_SUCCESS,
  GET_CAMPAIGNS_FAIL,
  GET_CAMPAIGNS_AVAILABLE,
  GET_CAMPAIGNS_AVAILABLE_SUCCESS,
  GET_CAMPAIGNS_AVAILABLE_FAIL,
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  ATTACH_MANAGE,
  ATTACH_MANAGE_SUCCESS,
  ATTACH_MANAGE_FAIL,
  ORDER_BOUGHT,
  ORDER_BOUGHT_SUCCESS,
  ORDER_BOUGHT_FAIL,
  GET_RANDOM_PRODUCTS,
  GET_RANDOM_PRODUCTS_SUCCESS,
  GET_RANDOM_PRODUCTS_FAIL,
  UPDATE_FAKE_ADDRESS,
  UPDATE_FAKE_ADDRESS_SUCCESS,
  UPDATE_FAKE_ADDRESS_FAIL,
  UPDATE_ACTIVE_PROFILE,
  SET_WORKFLOW_TYPE_ID,
  ADD_REVIEW_JOB,
  SET_REVIEW_JOBS_ERROR,
  GET_PRODUCT_IMAGE,
  GET_PRODUCT_IMAGE_SUCCESS,
  GET_PRODUCT_IMAGE_FAIL
} from "../../actions/orders";
import {LOGIN_WITH_JWT, LOGOUT_WITH_JWT} from "../../actions/auth/loginActions";

const initialState = {
  loading: false,
  refresh: false,
  loadingMap: {},
  workflowTypeId: 0,
  activeProfile: null,
  selectedOrder: null,
  beforeFilter: [],
  value: '',
  accounts: [],
  campaigns: {},
  campaignsAvailable: {
    activeCampaigns: {
      fbaAndSda: 0,
      fbmAndRestricted: 0,
      fbmCodeword: 0
    },
    availableReviewJobsToday: {
      onlyFba: 0,
      fbmToTesterAddress: 0,
      fbmWithCodeword: 0
    },
    sales: {
      activeCampaigns: 0,
      availableOrderSalesToday: 0
    }
  },
};

const normalizedProducts = (products) => {
  let arrayProducts = Array.isArray(products) ? products : [products];
  return humps.camelizeKeys(arrayProducts).map((product) => {
    const {link, image, price, currency, asin, title, iD, jobs, text, stars, ...rest} = product;
    let normalizedProduct = {
      productUrl: link,
      productImageUrl: image,
      productPrice: price,
      productCurrency: currency,
      productAsin: asin,
      productTitle: title,
      testerjobCampaignId: iD,
      testerjobJobs: jobs,
      testerjobText: text,
      testerjobStars: stars,
      ...rest
    };
    return normalizedProduct
  })
};

const normalizedReviewJobs = (items) => humps.camelizeKeys(items.map(item => ({campaign: item, campaignId: item.id})))

const updateOrders = (orders, payload) => {
  let newOrders = [...orders];
  if (payload.allChange) {
    newOrders = newOrders.map(order => ({...order, [payload.key]: payload.data}))
  } else {
    newOrders[payload.index][payload.key] = payload.data;
  }
  return newOrders
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WORKFLOW_TYPE_ID:
      return {...state, workflowTypeId: action.workflowTypeId}
    case GET_PROFILE:
      return {...state, loading: true, loadingMap: {...state.loadingMap, "setActiveProfile": true}};
    case GET_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        loadingMap: {...state.loadingMap, "setActiveProfile": false},
        activeProfile: humps.camelizeKeys(action.data),
      };
    case GET_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        loadingMap: {...state.loadingMap, "setActiveProfile": false},
      };
    case GET_ACCOUNTS:
    case GET_CAMPAIGNS:
    case GET_CAMPAIGNS_AVAILABLE:
    case UPDATE_PROFILE:
      return {...state, loading: true};
    case GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loading: false,
        accounts: humps.camelizeKeys(action.data.filter(account => account.id !== action.payload)),
      };
    case GET_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        loading: false,
        campaigns: humps.camelizeKeys(action.data),
      };
    case GET_CAMPAIGNS_AVAILABLE_SUCCESS:
      return {...state, loading: false, campaignsAvailable: action.data};
    case UPDATE_PROFILE_SUCCESS:
      return {...state, activeProfile: {...state.activeProfile, ...action.data}};
    case GET_ACCOUNTS_FAIL:
    case GET_CAMPAIGNS_FAIL:
    case GET_CAMPAIGNS_AVAILABLE_FAIL:
    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
      };
    case GET_PRODUCT:
    case GET_RANDOM_PRODUCTS:
      return {...state, loading: true};
    case GET_PRODUCT_SUCCESS:
    case GET_RANDOM_PRODUCTS_SUCCESS:
    case ADD_REVIEW_JOB:
      let newOrders = [...state.activeProfile.orders];
      if (newOrders[action.payload.index] && newOrders[action.payload.index].reviewJobs) {
        action.payload.data = [...state.activeProfile.orders[action.payload.index].reviewJobs, ...normalizedReviewJobs(action.data)]
      } else {
        action.payload.data = normalizedReviewJobs(action.data)
      }
      return {
        ...state,
        loading: false,
        activeProfile: {...state.activeProfile, orders: updateOrders(newOrders, action.payload)}
      };
    case GET_PRODUCT_FAIL:
    case GET_RANDOM_PRODUCTS_FAIL:
      return {...state, loading: false};
    case ORDER_BOUGHT:
    case ATTACH_MANAGE:
      return {...state, loading: true};
    case ORDER_BOUGHT_SUCCESS:
    case ATTACH_MANAGE_SUCCESS:
      return {
        ...state,
        loading: false,
        activeProfile: {...state.activeProfile, orders: updateOrders(state.activeProfile.orders, action.payload)},
      };
    case ORDER_BOUGHT_FAIL:
    case ATTACH_MANAGE_FAIL:
      return {...state, loading: false};
    case UPDATE_FAKE_ADDRESS:
      return {...state, loading: true}
    case UPDATE_FAKE_ADDRESS_SUCCESS:
      return {...state, activeProfile: {...state.activeProfile, billingStreet: action.payload}}
    case UPDATE_FAKE_ADDRESS_FAIL:
      return {...state, loading: false};
    case LOCK_REVIEW_JOB:
    case UNLOCK_REVIEW_JOB:
    case REMOVE_REVIEW_JOB:
      return {
        ...state,
        loadingMap: {...state.loadingMap, [action.campaignId]: true, [action.campaignId + 'error']: false}
      };
    case LOCK_REVIEW_JOB_SUCCESS:
    case UNLOCK_REVIEW_JOB_SUCCESS:
      return {
        ...state,
        activeProfile: {
          ...state.activeProfile, orders: [...state.activeProfile.orders.map(order => {
            if (order.id === action.orderId) {
              order.reviewJobs = [...order.reviewJobs.map(reviewJob => reviewJob.campaignId === action.data.campaignId ? action.data : reviewJob)];
            }
            return order
          })]
        },
        loadingMap: {...state.loadingMap, [action.campaignId]: false, [action.campaignId + 'error']: false}
      };
    case REMOVE_REVIEW_JOB_SUCCESS:
      return {
        ...state,
        activeProfile: {
          ...state.activeProfile, orders: [...state.activeProfile.orders.map(order => {
            if (order.id === action.orderId) {
              order.reviewJobs = order.reviewJobs.filter((reviewJob => reviewJob.campaignId !== action.campaignId));
            }
            return order
          })]
        },
        loadingMap: {...state.loadingMap, [action.campaignId]: false, [action.campaignId + 'error']: false}
      }
    case LOCK_REVIEW_JOB_FAIL:
    case UNLOCK_REVIEW_JOB_FAIL:
    case REMOVE_REVIEW_JOB_FAIL:
      return {
        ...state,
        loadingMap: {...state.loadingMap, [action.campaignId]: false, [action.campaignId + 'error']: true}
      }
    case ORDER_LOCK:
      return {
        ...state,
        loading: true,
        loadingMap: {...state.loadingMap, [action.payload.testerjobCampaignId]: true}
      };
    case ORDER_LOCK_SUCCESS:
      let newReviews = [...state.activeProfile.orders[action.payload.index].reviewJobs].map((item) => {
        if (item.testerjobCampaignId === action.data.testerjobCampaignId) {
          return {...action.data, error: false}
        }
        return item
      });
      action.payload.data = newReviews;
      return {
        ...state,
        loading: false,
        activeProfile: {...state.activeProfile, orders: updateOrders(state.activeProfile.orders, action.payload)},
        loadingMap: {...state.loadingMap, [action.payload.testerjobCampaignId]: false}
      };
    case ORDER_LOCK_FAIL:
      return {
        ...state,
        loading: false,
        loadingMap: {...state.loadingMap, [action.payload.testerjobCampaignId]: false}
      };
    case GET_RANDOM_PRODUCT:
      return {...state, loading: true};
    case GET_RANDOM_PRODUCT_SUCCESS:
      let nextReviews = [...state.activeOrder.reviews, ...normalizedProducts(action.data['tj_product'])];
      return {
        ...state,
        loading: false,
        activeOrder: {...state.activeOrder, reviews: nextReviews}
      };
    case GET_RANDOM_PRODUCT_FAIL:
      return {...state, loading: false, fetchError: action.error.data};

    case ORDER_LOCK_PRODUCTS:
    case ORDER_LOCK_GIFT:
      return {...state, loading: true};
    case ORDER_LOCK_PRODUCTS_SUCCESS:
    case ORDER_LOCK_GIFT_SUCCESS:
      return {
        ...state,
        loading: false,
        activeOrder: null,
        fetchSuccess: action.message ? {message: action.message} : null,
        refresh: true
      };
    case ORDER_LOCK_PRODUCTS_FAIL:
    case ORDER_LOCK_GIFT_FAIL:
      return {...state, loading: false, fetchError: action.error.data};
    case ORDER_FINISH:
      return {...state, loading: true};
    case ORDER_FINISH_SUCCESS:
      return {
        ...state,
        loading: false,
        activeProfile: null,
      };
    case ORDER_FINISH_FAIL:
      return {...state, loading: false};
    case SET_ACTIVE_ORDER:
      return {...state, activeOrder: action.payload};
    case UPDATE_ACTIVE_PROFILE:
      return {
        ...state,
        activeProfile: {
          ...state.activeProfile, orders: [...state.activeProfile.orders].map((order, index) => ({
            ...order,
            reviewJobs: action.data[index]
          }))
        }
      };
    case GET_PRODUCT_IMAGE:
      return {...state, loadingMap: {...state.loadingMap, ['image' + action.payload.id]: true}};
    case GET_PRODUCT_IMAGE_SUCCESS:
      action.payload.data = [...state.activeProfile.orders[action.payload.index].reviewJobs].map(job => {
        return action.payload.campaignId === job.campaignId ? {...job, ...action.data} : job
      });
      return {
        ...state,
        loadingMap: {...state.loadingMap, ['image' + action.payload.id]: false},
        activeProfile: {...state.activeProfile, orders: updateOrders(state.activeProfile.orders, action.payload)},

      };
    case GET_PRODUCT_IMAGE_FAIL:
      return {...state, loadingMap: {...state.loadingMap, ['image' + action.payload.id]: false}};
    case SET_REVIEW_JOBS_ERROR:
      return {
        ...state,
        loadingMap: {...state.loadingMap, ...action.data}
      };
    case RESET_FETCH_ERROR:
      return {...state, fetchError: null};
    case RESET_FETCH_SUCCESS:
      return {...state, fetchSuccess: null};
    case LOGOUT_WITH_JWT:
    case LOGIN_WITH_JWT: {
      return initialState;
    }
    default:
      return state
  }
};

export default ordersReducer
