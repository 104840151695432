import axios from "axios";
import humps from 'humps';
import {API_URL} from "../../../configs/constants";
import {setFetchMessage} from "../notification";
import {isError, getErrorMessage} from "../../../utility/helpers";


export const GET_STATS = "GET_STATS";
export const GET_STATS_SUCCESS = "GET_STATS_SUCCESS";
export const GET_STATS_FAIL = "GET_STATS_FAIL";
export const GET_OPEN_AMOUNT_SUCCESS = "GET_OPEN_AMOUNT_SUCCESS";

const typesStatPath = {
  writer: 'review-writer',
  supervisor: 'supervisor',
  administrator: 'administrator'
};

export const getStats = (type, requestData) => {
  return async dispatch => {
    dispatch({type: GET_STATS});
    try {
      const response = await axios.post(`${API_URL}/api/user-action-log/statistics/${typesStatPath[type]}`, requestData);
      isError(response);

      const {managers: {data = [], ...pagination} = {}} = response.data;
      dispatch({
        type: GET_STATS_SUCCESS,
        data: humps.camelizeKeys(data),
        pagination: humps.camelizeKeys(pagination)
      })
    } catch (error) {
      dispatch({type: GET_STATS_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const getOpenAmount = () => {
  return async dispatch => {
    try {
      const response = await axios.get(`${API_URL}/api/finance/statistics/open-amount`);
      isError(response);
      dispatch({type: GET_OPEN_AMOUNT_SUCCESS, data: humps.camelizeKeys(response.data).openAmount});
    } catch (error) {
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

