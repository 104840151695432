import axios from "axios";
import humps from 'humps';
import {API_URL} from "../../../configs/constants";
import {setFetchMessage} from "../notification";
import {isError, getErrorMessage} from "../../../utility/helpers";

export const GET_PROFILE_NOTES = "GET_PROFILE_NOTES";
export const GET_PROFILE_NOTES_SUCCESS = 'GET_PROFILE_NOTES_SUCCESS';
export const GET_PROFILE_NOTES_FAIL = 'GET_PROFILE_NOTES_FAIL';
export const ADD_PROFILE_NOTE = "ADD_PROFILE_NOTE";
export const ADD_PROFILE_NOTE_SUCCESS = 'ADD_PROFILE_NOTE_SUCCESS';
export const ADD_PROFILE_NOTE_FAIL = 'ADD_PROFILE_NOTE_FAIL';

export const getProfileNotes = (profileId, isSales) => {
  return async dispatch => {
    dispatch({type: GET_PROFILE_NOTES});
    try {
      const response = await axios.get(`${API_URL}/api/${isSales ? 'sales/' : ''}profile/note/${profileId}`);
      const data = humps.camelizeKeys(response.data['profile_notes']).map(({user, ...note}) => ({
        ...note,
        email: user.email
      })).sort((a, b) => {
        if (a.createdAt > b.createdAt) {
          return -1
        } else if (a.createdAt < b.createdAt) {
          return 1
        } else {
          return 0
        }
      });
      isError(response);
      dispatch({
        type: GET_PROFILE_NOTES_SUCCESS,
        data
      })
    } catch (error) {
      dispatch({type: GET_PROFILE_NOTES_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};

export const addProfileNote = (data, isSales) => {
  return async (dispatch, getState) => {
    dispatch({type: ADD_PROFILE_NOTE});
    try {
      const response = await axios.post(`${API_URL}/api/${isSales ? 'sales/' : ''}profile/note`, humps.decamelizeKeys(data));
      const email = getState().auth.login.user.email;
      const result = {...data, email, createdAt: new Date()};
      isError(response);
      dispatch({
        type: ADD_PROFILE_NOTE_SUCCESS,
        data: result
      });
      return response
    } catch (error) {
      dispatch({type: ADD_PROFILE_NOTE_FAIL});
      dispatch(setFetchMessage(getErrorMessage(error), 'error'));
    }
  }
};
