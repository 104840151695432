import humps from 'humps';
import {LOGIN_WITH_JWT, LOGOUT_WITH_JWT} from "../../actions/auth/loginActions";
import {
  GET_LOGS,
  GET_LOGS_SUCCESS,
  GET_LOGS_FAIL,
  GET_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAIL,
  CHANGE_USER_PARAM,
  CHANGE_USER_PARAM_SUCCESS,
  CHANGE_USER_PARAM_FAIL,
  GET_MODEMS_SUCCESS,
  ADD_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  REMOVE_USER,
  REMOVE_USER_SUCCESS,
  REMOVE_USER_FAIL,
  GET_USER_ROLES_SUCCESS,

} from "../../actions/settings";

const initialState = {
  loading: false,
  loadingMap: [],
  users: [],
  userRoles: [],
  modems: [],
  logs: {
    proxy: [],
    tj: [],
    campaign: []
  },
  pagination: {},

};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LOGS:
    case GET_USERS:
    case ADD_USER:
    case REMOVE_USER:
      return {...state, loading: true};
    case GET_USER_ROLES_SUCCESS:
      return {...state, userRoles: action.data};
    case  GET_LOGS_SUCCESS:
      return {
        ...state,
        logs: {...state.logs, [action.payload.name]: humps.camelizeKeys(action.data)},
        pagination: humps.camelizeKeys(action.payload.pagination),
        loading: false
      };
    case GET_USERS_SUCCESS:
      return {...state, users: humps.camelizeKeys(action.data), loading: false};
    case ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: [...state.users, {...humps.camelizeKeys(action.data)}]
      };
    case REMOVE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        users: state.users.filter(user => user.id !== action.data.id)
      };
    case GET_LOGS_FAIL:
    case GET_USERS_FAIL:
    case ADD_USER_FAIL:
    case REMOVE_USER_FAIL:
      return {...state, loading: false};
    case CHANGE_USER_PARAM:
      return {...state, loadingMap: {...state.loadingMap, [action.payload.userId]: true}};
    case CHANGE_USER_PARAM_SUCCESS:
      return {
        ...state,
        loadingMap: {...state.loadingMap, [action.payload.userId]: false},
        users: [...state.users.map(user => {
          if (user.id === action.payload.userId) {
            user = {...user, ...action.payload.param}
          }
          return user
        })]
      };
    case CHANGE_USER_PARAM_FAIL:
      return {...state, loadingMap: {...state.loadingMap, [action.payload.userId]: false}};
    case GET_MODEMS_SUCCESS:
      return {...state, modems: action.data.proxyModems};
    case LOGOUT_WITH_JWT:
    case LOGIN_WITH_JWT: {
      return initialState;
    }
    default:
      return state
  }
};

export default settingsReducer;
